import gql from 'graphql-tag'

export const GET_POINT_EXCHANGE_PRODUCTS = gql`
  query {
    clientPointExchangeProductList {
      id
      name
      spPoint
      fpPoint
      tvpPoint
      companyName
      saleEndAt
    }
  }
`

export const ORDER_POINT_EXCHANGE_PRODUCTS = gql`
  mutation (
    $address: CreatePointExchangeAddressInput
    $orders: [CreatePointExchangeOrderInput!]!
    $contractId: Int
  ) {
    createMyPointExchange (
      address: $address
      orders: $orders
      contractId: $contractId
    )
  }
`
export const GET_MY_POINT_EXCHANGE_PRODUCTS = gql`
  query {
    myPointExchangeList {
      items {
        confirmedAt # Confirmation Date If this is Not null, "Ticket Issuer Confirmed. 確認日時 ここがNot nullなら 「チケット発行元確認済み」
        createdAt #Application Date 申込日時
        quantity #Quantity 数量
        tvpPoint # If paid by TVP, the value will be placed here. TVPで払った場合はここに値が入る
        spPoint # SP Price SP価格
        fpPoint # FP Price FP 価格
        pointExchangeProduct {
          name # Product name 商品名
        }
        name
      }
    }
  }
`
