<template>
  <footer class="bg-primary py-2">
    <div class="py-4 px-3 mt-4 --white w-100 text-center">
      Copyright © Tokyu Corporation All Right Reserved
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PublicLayoutFooter'
}
</script>

<style lang="scss" scoped>
@use '../../../assets/styles/modules/variables-scss' as v;

footer {
  position: relative;
  z-index: 1;
  margin-top: auto;
  flex-shrink: 0;
  border-radius: 24px 24px 0 0;
  @media (min-width: v.$breakpoint__md) {
    background-color: var(--color__primary);
    border-radius: 0;
  }
}
</style>
