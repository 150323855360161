import { contractIsForNewBooking, ProductTypeNameToId } from '../../../constants/product'
import actions from './actions'

const contract = {
  state: {
    contractPoints: [],
    tvp: null,
    contracts: []
  },
  mutations: {
    setCurrentTvp (state, tvp) {
      state.tvp = tvp
    },
    setContractPoints (state, contractPoints) {
      state.contractPoints = contractPoints
    },
    setContracts (state, contracts) {
      state.contracts = contracts
    }
  },
  getters: {
    remainingPoints: state => {
      const points = state.contractPoints ?? []
      if (state.tvp > 0) {
        points.push({
          type: 'tvp',
          contractId: null,
          name: '東急バケーションズポイント',
          pointRemains: state.tvp
        })
      }
      return points
    },
    remainingTvp: state => state.tvp,
    getPointByContractId: (state, getters) => contractId => {
      return getters.remainingPoints.find(p => p.contractId === contractId)
    },
    getContractsForBooking: (state) => {
      return state
        .contracts
        .filter(c => c.productType.id === ProductTypeNameToId.VS) // only 'VS' is supported in this web
        .filter(c => contractIsForNewBooking(c.productType.id))
    },
    getContractById: (state) => contractId => {
      return state.contracts.find(c => c.id === contractId)
    }
  },
  actions
}

export default contract
