<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 239.129 57.489">
    <g id="Сгруппировать_4415" data-name="Сгруппировать 4415" transform="translate(-125.805 -283.593)">
      <path id="Контур_4354" data-name="Контур 4354" d="M129.429,299.278h1.878V285.755h3.61v-1.732H125.8v1.732h3.624Z" transform="translate(0 -0.132)" :fill="commonColor"/>
      <path id="Контур_4355" data-name="Контур 4355" d="M149.639,283.593a7.816,7.816,0,0,0-5.664,2.318,7.883,7.883,0,0,0,.225,11.47,8.071,8.071,0,0,0,11.028-.256,7.537,7.537,0,0,0,2.356-5.587,8,8,0,0,0-7.945-7.945Zm4.269,12.325a6.166,6.166,0,0,1-8.631-8.8,5.88,5.88,0,0,1,4.329-1.786,5.821,5.821,0,0,1,4.323,1.79,6.362,6.362,0,0,1-.021,8.8Z" transform="translate(-4.855 0)" :fill="commonColor"/>
      <path id="Контур_4356" data-name="Контур 4356" d="M171.579,284.023H169.7v15.255h1.88v-6.372l.758-.762,5.776,7.134h2.531l-6.971-8.387,6.857-6.868h-2.464l-6.486,6.65Z" transform="translate(-13.482 -0.132)" :fill="commonColor"/>
      <path id="Контур_4357" data-name="Контур 4357" d="M192.086,292.655v6.623h1.88v-6.623l4.977-8.632h-2.156l-3.759,6.548-3.747-6.548h-2.156Z" transform="translate(-18.834 -0.132)" :fill="commonColor"/>
      <path id="Контур_4358" data-name="Контур 4358" d="M213.37,297.842a3.494,3.494,0,0,1-2.988-1.463,5.387,5.387,0,0,1-.71-3.117v-9.24h-1.878v9.893a5.584,5.584,0,1,0,11.164,0v-9.893H217.08v9.24a5.315,5.315,0,0,1-.71,3.117A3.511,3.511,0,0,1,213.37,297.842Z" transform="translate(-25.181 -0.132)" :fill="commonColor"/>
      <path id="Контур_4359" data-name="Контур 4359" d="M227.25,316.825v5.719a13.391,13.391,0,0,0-8.661-3.5,11.011,11.011,0,0,0-8.307,3.544,11.945,11.945,0,0,0-3.4,8.616,11.656,11.656,0,0,0,3.4,8.493,11.223,11.223,0,0,0,8.328,3.46,10.215,10.215,0,0,0,4.33-.829A12.822,12.822,0,0,0,225,341.209a24.843,24.843,0,0,0,2.248-1.7v5.821a17.464,17.464,0,0,1-8.7,2.341,16.049,16.049,0,0,1-11.7-4.785,15.849,15.849,0,0,1-4.828-11.641,16.413,16.413,0,0,1,4.039-10.9,16.072,16.072,0,0,1,12.864-5.864A16.356,16.356,0,0,1,227.25,316.825Z" transform="translate(-23.408 -9.488)" :fill="commonColor"/>
      <path id="Контур_4360" data-name="Контур 4360" d="M296.3,319.917v27.451H291.47V319.917h-7.353v-4.533h19.514v4.533Z" transform="translate(-48.623 -9.764)" :fill="commonColor"/>
      <path id="Контур_4361" data-name="Контур 4361" d="M323.907,315.384v31.984H319.08V315.384Z" transform="translate(-59.361 -9.764)" :fill="commonColor"/>
      <path id="Контур_4362" data-name="Контур 4362" d="M461.723,322.175l-4.862.014a6.755,6.755,0,0,0-2.158-2.483,4.854,4.854,0,0,0-2.673-.664,4.937,4.937,0,0,0-3.334,1.14A3.527,3.527,0,0,0,447.369,323q0,2.341,3.477,3.768l3.194,1.3a13.631,13.631,0,0,1,5.7,3.843,8.641,8.641,0,0,1,1.8,5.564,9.778,9.778,0,0,1-2.94,7.293,10.1,10.1,0,0,1-7.353,2.9,9.835,9.835,0,0,1-6.878-2.462,11.583,11.583,0,0,1-3.425-8.019l4.977,0a8.359,8.359,0,0,0,1.161,3.9,5.511,5.511,0,0,0,8.1.563,5.235,5.235,0,0,0,1.5-3.835,5.37,5.37,0,0,0-.26-1.711,4.273,4.273,0,0,0-.809-1.427,6.293,6.293,0,0,0-1.418-1.222,14.157,14.157,0,0,0-2.07-1.092l-3.088-1.28q-6.566-2.779-6.57-8.121a7.711,7.711,0,0,1,2.756-6.03,10.2,10.2,0,0,1,6.859-2.444C455.849,314.413,460.193,316.823,461.723,322.175Z" transform="translate(-96.789 -9.487)" :fill="commonColor"/>
      <path id="Контур_4363" data-name="Контур 4363" d="M149.031,315.384l-8.968,22.166-8.846-22.166h-5.283l13.076,32.063h1.919l13.388-32.063Z" transform="translate(-0.04 -9.764)" :fill="commonColor"/>
      <path id="Контур_4364" data-name="Контур 4364" d="M172.462,315.3l-14.956,32.12h5.212l3.57-7.786H180.01l3.365,7.786h5.291L174.24,315.3Zm-4.161,19.79,4.984-10.9,4.753,10.9Z" transform="translate(-9.737 -9.74)" :fill="commonColor"/>
      <path id="Контур_4365" data-name="Контур 4365" d="M418.733,315.326v22.13l-21.15-22.13h-1.911v32.056h4.772V324.964l21.53,22.46h1.53v-32.1Z" transform="translate(-82.886 -9.746)" :fill="commonColor"/>
      <path id="Контур_4366" data-name="Контур 4366" d="M387.6,352.744" transform="translate(-80.406 -21.239)" :fill="commonColor"/>
      <g id="Сгруппировать_4414" data-name="Сгруппировать 4414" transform="translate(269.362 301.95)">
        <path id="Контур_4367" data-name="Контур 4367" d="M354.236,355c-5.624,3.371-10.92,1.847-14.222.617a13.123,13.123,0,0,0,13.884,4.232c6.024-1.784,6.429-4.478,11.52-4.973-2.249,1.066-3.825,3.112-5.74,4.734a13.815,13.815,0,0,1-4.792,2.634,19.566,19.566,0,0,0,15.082-7.308c.062-.077.125-.155.181-.233l.276-.385.427-.6S362.217,350.216,354.236,355Z" transform="translate(-335.153 -323.107)" :fill="circleColor"/>
        <path id="Контур_4368" data-name="Контур 4368" d="M349.558,342.866c-4.42-.612-8.2-.991-11.005-1.226q-2.231-.12-4.488-.141c-.226,0-.589,0-.885,0,.111,1.1.863,6.262,5.485,8.369,2.809,1.277,7.231,1.492,12.367-1.01,8.967-4.371,18.4.556,18.4.556l.476-.836s-.727-.317-1.9-.691A97.026,97.026,0,0,0,349.558,342.866Z" transform="translate(-333.054 -319.734)" :fill="circleColor"/>
        <path id="Контур_4369" data-name="Контур 4369" d="M352.577,310.087A19.589,19.589,0,0,0,333,329.227q1.336.045,2.677.127,1.275.079,2.544.189a14.361,14.361,0,1,1,27.267,6.435q-.285.584-.62,1.14,2.513.863,4.958,1.858c.167-.31.325-.626.477-.945a19.593,19.593,0,0,0-17.724-27.946Z" transform="translate(-332.998 -310.087)" :fill="circleColor"/>
      </g>
      <path id="Контур_4370" data-name="Контур 4370" d="M327.467,333.9a.418.418,0,0,0-.044-.014,97.62,97.62,0,0,0-60.572-1.2L259.1,315.3H257.34L242.391,347.36h5.226a97.375,97.375,0,0,1,16.666-9.006L268.2,347.3h5.174l-4.751-10.653a97.69,97.69,0,0,1,58.7-2.5l.082.023a.145.145,0,0,0,.062-.271Zm-76.036,5,6.643-14.733,4.37,9.986A97.4,97.4,0,0,0,251.432,338.9Z" transform="translate(-35.808 -9.74)" :fill="commonColor"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    width: {
      type: Number,
      default: 239
    },
    height: {
      type: Number,
      default: 57
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      commonColor: !this.light ? '#008fe1' : '#fff',
      circleColor: !this.light ? '#fdac00' : '#fff'
    }
  }
}
</script>
