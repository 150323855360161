<template>
  <div class="bg-silver h-100">
    <PublicLayoutHeader class="mt-8 mb-4"  v-if="!$route.meta.hideHeader" />
    <PublicLayoutMain class="wrapper" :class="{
        'wrapper__no-header': $route.meta.hideHeader && !$route.meta.hideFooter && !$route.meta.isMiniFooter,
        'wrapper__no-header__mini': $route.meta.hideHeader && $route.meta.isMiniFooter,
        'wrapper__no-footer': $route.meta.hideFooter && !$route.meta.hideHeader,
        'wrapper__no-header__no-footer': $route.meta.hideFooter && $route.meta.hideFooter,
        'wrapper__common': !$route.meta.hideHeader && !$route.meta.hideFooter && !$route.meta.isMiniFooter,
        'wrapper__common__mini': !$route.meta.hideHeader && !$route.meta.hideFooter && $route.meta.isMiniFooter
      }" />
    <PublicLayoutFooter v-if="$route.meta.isMiniFooter && !$route.meta.hideFooter" />
    <AppLayoutFooter v-if="!$route.meta.isMiniFooter && !$route.meta.hideFooter" />
    <OverLayLoading />
  </div>
</template>

<script>
import {
  PublicLayoutHeader,
  PublicLayoutMain,
  PublicLayoutFooter
} from './components'
import OverLayLoading from '@/components/OverLayLoading'
import AppLayoutFooter from '@/layout/AppLayout/components/AppLayoutFooter'

export default {
  name: 'PublicLayout',
  components: {
    PublicLayoutHeader,
    PublicLayoutMain,
    PublicLayoutFooter,
    AppLayoutFooter,
    OverLayLoading
  }
}
</script>
