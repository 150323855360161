import i18n from '../plugins/i18n'
import { range } from '@/utils/generator'

export const getDayText = (d) => {
  switch (d) {
    case 0:
      return i18n.t('daysOfWeek.sun')
    case 1:
      return i18n.t('daysOfWeek.mon')
    case 2:
      return i18n.t('daysOfWeek.tue')
    case 3:
      return i18n.t('daysOfWeek.wed')
    case 4:
      return i18n.t('daysOfWeek.thu')
    case 5:
      return i18n.t('daysOfWeek.fri')
    case 6:
      return i18n.t('daysOfWeek.sat')
  }
  // Intl.DateTimeFormat -.-
}

export const getDateString = (year, month, day) => {
  return `${year}-${zPad(month)}-${zPad(day)}`
}

export const getDayList = (year, month) => {
  if (!year || !month) return []
  const days = new Date(year, month, 0).getDate()
  return range(1, days)
}

export const getYearListOfAge = (minAge = 0) => {
  const yearList = []
  const start = 1900
  const end = new Date().getFullYear()
  for (let year = start; year <= end - minAge; year++) {
    yearList.push(year)
  }
  yearList.sort((a, b) => b - a)
  return yearList
}

export const getMonthList = () => {
  const monthList = []
  for (let i = 1; i <= 12; i++) {
    monthList.push(i)
  }
  return monthList
}

export const getNextYearsFromCurrent = (from, nextNYears) => {
  const yearList = []
  for (let i = 0; i < nextNYears; i++) {
    yearList.push(from + i)
  }
  return yearList
}

export const jaShortDate = (date) => {
  date = parseDate(date)
  return `${date.getMonth() + 1}月${date.getDate()}日`
}

export const jaShortDateWithDow = (date) => {
  date = parseDate(date)
  return `${date.getMonth() + 1}/${date.getDate()} (${getDayText(date.getDay())})`
}

function zPad (num) {
  return `${num}`.padStart(2, '0')
}

export function parseDate (date) {
  // if it's in iso date format, reconstruct it by new Date(year, month, date)
  if (typeof date === 'string') {
    const match = date.match(/^(\d{4})-(\d{2})-(\d{2})$/)
    if (match) {
      return new Date(match[1], match[2] - 1, match[3])
    }
  }
  return new Date(date)
}

export function delimitedDateString (
  date,
  delimiter,
  options
) {
  date = parseDate(date)
  const pad = (v) => {
    if (options?.noPadding) return v
    return zPad(v)
  }
  return `${date.getFullYear()}${delimiter}${pad(
    date.getMonth() + 1
  )}${delimiter}${pad(date.getDate())}`
}

export const isoDate = (date) => {
  return delimitedDateString(date, '-')
}

export const createIsoDate = (year, month, date) => {
  return isoDate(new Date(year, month - 1, date))
}

export const getNumberOfDaysBetween = (fromDate, toDate) => {
  fromDate = parseDate(fromDate)
  toDate = parseDate(toDate)
  const diffTime = Math.abs(toDate - fromDate)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const jaLongDate = (date, { hideDow } = {}) => {
  date = parseDate(date)
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日${hideDow ? '' : `(${getDayText(date.getDay())})`}`
}

export const jaTime = (date) => {
  date = parseDate(date)
  const timeString = date.toTimeString().substring(0, 5)
  return `${timeString.substring(0, 2)}時${timeString.substring(3, 5)}分`
}

export const jaLongDateTime = (date, { hideDow } = {}) => {
  const dateString = jaLongDate(date, { hideDow: !!hideDow })
  const timeString = jaTime(date)

  return `${dateString} ${timeString}`
}

export const jaLongDateSlash = (date) => {
  date = parseDate(date)
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} (${getDayText(date.getDay())})`
}

export const jaShortDateSlash = (date) => {
  date = parseDate(date)
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

// date in format of yyyy MM dd HH:mm(:ss)
export function getDateTime (datetime, options = {
  withSecond: false,
  delimeter: null
}) {
  const date = parseDate(datetime)

  return `${delimitedDateString(date, options?.delimeter ?? '-')} ${date.toTimeString().substring(0, options?.withSecond ? 8 : 5)}`
}

export const jaLongDateRange = (fromDate, toDate) => {
  return `${jaLongDate(fromDate)} ~ ${jaLongDate(toDate)}`
}

export const addDays = (date, n) => {
  const dateObj = parseDate(date)
  dateObj.setDate(dateObj.getDate() + n)

  if (typeof date === 'string') return isoDate(dateObj)
  return dateObj
}

export function getDateRangeArray (startDate, endDate, {
  excludeLast
} = {
  excludeLast: false
}) {
  startDate = parseDate(startDate)
  endDate = parseDate(endDate)

  const dates = []
  const currentDate = startDate

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate < endDate || (currentDate.getTime() === endDate.getTime() && !excludeLast)) {
    dates.push(isoDate(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export function getStayDateRangeArray (checkInDate, checkOutDate) {
  return getDateRangeArray(checkInDate, checkOutDate, { excludeLast: true })
}

export function getNowPlusOne () {
  return new Date(Date.now() + (3600 * 1000 * 24))
}

export function isValidDate (day, month, year) {
  day = parseInt(day)
  month = parseInt(month)
  year = parseInt(year)
  if (day < 1 || day > 31) return false
  const date = new Date(year, month - 1, day)
  return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24
// a and b are javascript Date objects
export function dateDiffInDays (a, b) {
  if (!a || !b) {
    return null
  }
  a = parseDate(a)
  b = parseDate(b)
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export function cardExpireToDate (expire) {
  if (!expire) return expire
  // expire is YYMM format, so
  return `20${expire.substring(0, 2)}/${expire.substring(2)}`
}

export function cardExpireMonths (expire) {
  if (!expire) return null

  const expireYear = Number(`20${expire.substring(0, 2)}`)
  const expireMonth = Number(`${expire.substring(2)}`)

  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1

  const diffMonths = (expireYear * 12 + expireMonth) - (currentYear * 12 + currentMonth)
  return diffMonths
}

export function giftTicketSaleEndSoon (saleEndAt) {
  if (!saleEndAt) return false
  const oneMonth = addMonthInTokyuWay(new Date(), 1)
  return oneMonth > new Date(saleEndAt)
}

export const getAgeFromDate = (birthdayDay) => {
  if (!birthdayDay) {
    return '-'
  }
  const date = new Date(birthdayDay)
  const today = new Date()
  const thisYearBirthday = new Date(today.getFullYear(), date.getMonth(), date.getDate())
  let age = today.getFullYear() - date.getFullYear()
  if (today < thisYearBirthday) {
    age--
  }
  return age
}

export const calculateDays = (bigValue, smallValue) => {
  const dateTo = parseDate(bigValue)
  const dateFrom = parseDate(smallValue)
  const days = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24)
  return Math.ceil(days)
}

export function isLastDayOfMonth (date) {
  date = parseDate(date)
  const month = date.getMonth()
  date.setDate(date.getDate() + 1)

  return date.getMonth() !== month
}

export function addMonthInTokyuWay (date, months) {
  date = parseDate(date)
  const monthsAfter = parseDate(date)
  monthsAfter.setMonth(date.getMonth() + months)

  if (date.getDate() !== monthsAfter.getDate()) {
    // this means that the same date does not exist in the specified month so get the last day of the previous month
    monthsAfter.setDate(0)
  }

  if (isLastDayOfMonth(date)) {
    // if this is the last day of month, the N month later date should also be the last day of month.
    // see uat member-164
    monthsAfter.setDate(1)
    monthsAfter.setMonth(monthsAfter.getMonth() + 1)
    monthsAfter.setDate(0)
  }

  return monthsAfter
}

export function getJstToday () {
  // get the date string YYYY-MM-DD format in JST
  // no matter the time zone of the browser is
  const date = new Date()
  const jstDate = new Date(date.getTime() + (date.getTimezoneOffset() + 540) * 60 * 1000)
  return isoDate(jstDate)
}
