import {
  REQUEST_MFA_CLIENT,
  CONFIRM_MFA_CLIENT,
  START_PASSWORD_RESET,
  CONFIRM_PASSWORD_RESET,
  REGISTER_EMAIL_START,
  REGISTER_FIRST_USER,
  GET_USER_INFO,
  LOGIN,
  REGISTER,
  SIGNUP_TOKEN_INFO,
  VALIDATE_PROMOCODE,
  VS_SIGNUP_SUBMIT_FORM
} from '@/api/graphql/user'
import { apolloClient } from '@/plugins/apollo'
import { refreshAccessToken } from '@/api/users'
import config from '../../../constants'

export default {

  async requestMFAClient (context, { apollo, email, password }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: REQUEST_MFA_CLIENT,
        variables: {
          email: email,
          password: password,
          vsIntent: true
        }
      }).then(data => {
        context.commit('setResetToken', data.data.requestMFAClient.token)
        resolve(data)
      }).catch(error => reject(error))
    })
  },

  async confirmMFAClient (context, { apollo, token, code }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: CONFIRM_MFA_CLIENT,
        variables: {
          token: token,
          code: code
        }
      }).then(data => {
        context.commit('setToken', data.data.confirmMFAClient.accessToken)
        context.commit('setRefreshToken', data.data.confirmMFAClient.refreshToken)
        context.commit('setShortToken', null)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  logOut () {
    const rememberLoginEmail = localStorage.getItem(config.rememberLoginEmail)
    const savedLoginEmail = localStorage.getItem(config.savedLoginEmail)

    // Clear all data except savedLoginEmail and rememberLoginEmail
    localStorage.clear()
    localStorage.setItem(config.rememberLoginEmail, rememberLoginEmail)
    localStorage.setItem(config.savedLoginEmail, savedLoginEmail)
  },
  async startPasswordReset (context, { apollo, email }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: START_PASSWORD_RESET,
        variables: {
          email: email
        }
      }).then(data => {
        context.commit('setResetToken', data.data.requestResetPasswordClient.token)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  async confirmPasswordReset (context, { apollo, code, token, password }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: CONFIRM_PASSWORD_RESET,
        variables: {
          code: code,
          token: token,
          password: password
        }
      }).then(data => {
        context.commit('setResetToken', null)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  async refreshAccessToken ({ commit, state }) {
    const { accessToken, refreshToken } = await refreshAccessToken(state.refreshToken)
    commit('setRefreshToken', refreshToken)
    commit('setToken', accessToken)
  },

  async startEmailRegister (_ctx, email) {
    return apolloClient.mutate({
      mutation: REGISTER_EMAIL_START,
      variables: { email }
    })
  },

  async registerFirstUser (_ctx, { token, memberId, birthday, tel, password }) {
    return apolloClient.mutate({
      mutation: REGISTER_FIRST_USER,
      variables: { token, memberId, birthday, tel, password }
    })
  },

  async loadUserInfo ({ commit }) {
    const res = await apolloClient.query({
      query: GET_USER_INFO
    })

    commit('setUserInfo', res.data.myUserInfo)
  },

  async register (context, { apollo, email, isCompany, promoCode }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: REGISTER,
        variables: {
          email: email,
          isCompany: isCompany,
          promoCode: promoCode
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  async signupTokenInfo (context, { apollo, token }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: SIGNUP_TOKEN_INFO,
        variables: {
          token: token
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  async validatePromocode (context, { apollo, promoCode }) {
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: VALIDATE_PROMOCODE,
        variables: {
          promoCode: promoCode
        }
      }).then(data => {
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  async regSubmit (context, { apollo, model, isCompany }) {
    const info = {}
    if (isCompany) {
      info.companyInfo = {
        companyName: model.corporationName,
        companyNameKana: model.corporateName,
        companyPicName: model.nameOfPersonInCharge,
        companyPicKana: model.katakana,
        companyPicDepartment: model.department,
        companyPicTitle: model.position
      }
    } else {
      info.individualInfo = {
        firstName: model.firstName,
        lastName: model.lastName,
        birthday: model.birthday,
        firstKana: model.firstKana,
        lastKana: model.lastKana,
        sex: model.sex
      }
    }
    return new Promise((resolve, reject) => {
      apollo.mutate({
        mutation: VS_SIGNUP_SUBMIT_FORM,
        variables: {
          token: model.token,
          email: model.email,
          password: model.password,
          isCompany: model.isCompany,
          clientCode: model.clientCode,
          postalCode: model.postalCode,
          prefecture: model.prefecture,
          locality: model.locality,
          address1: model.address1,
          address2: model.address2 ?? '',
          tel: model.tel,
          mobile: model.mobile,
          creditCardToken: model.creditCardToken,
          promoCode: model.promoCode,
          media: model.media,
          ...info
        }
      }).then(data => {
        resolve(data.data.vsSignupSubmitForm)
      }).catch(error => {
        reject(error)
      })
    })
  },
  logLastAction () {
    localStorage.setItem('lastAction', Date.now())
    sessionStorage.setItem('lastAction', Date.now()) // save it here too. 20220826, we are using this value to determine whether user has interacted with the web at least once to change the message upon auto-logout
  }
}
