import { isInMaintenance } from './app'

const defaultMessage = 'エラーが発生しました。お時間をおいてもう一度お試し下さい'
const maintenanceMessage = '現在サーバのメンテナンス中です。時間をおいて再度お試しください。'

export function gqlErrorContains (gqlError, message) {
  if (!gqlError.graphQLErrors) return false
  return gqlError.graphQLErrors.some(e => e.message === message)
}

export function gqlErrorToast (toast, gqlError, except = []) {
  const excluded = {}
  let hasError = false
  if (gqlError.graphQLErrors?.length) {
    for (let i = 0; i < gqlError.graphQLErrors.length; i++) {
      const e = gqlError.graphQLErrors[i]
      const exceptFound = except.find(message => message === e.message)
      if (exceptFound) {
        excluded[exceptFound] = e
      } else {
        hasError = true
        toast.error(
          translateGraphQLError(e.code, e.message)
        )
      }
    }
  } else if (gqlError.message === 'Network error: Failed to fetch' && isInMaintenance()) {
    // Network error: Failed to fetch is thrown if (not only if) cors error.
    // if it is in the maintenance time, then it was probably thrown because of the maintenance,
    // show the appropriate message
    toast.error(maintenanceMessage)
  } else {
    toast.error(defaultMessage)
  }

  return {
    excluded,
    hasError
  }
}

const translations = {
  ACCOMMODATION_POINT_MUST_BE_GREATER_THAN_OR_EQUAL_TO_0: '宿泊ポイントを0以上の整数で指定して下さい',
  ACCOMMODATION_PRICE_CSV_IMPORT_INVALID_FILE_FORMAT: '価格インポートCSVのフォーマットが不正です',
  ACCOMMODATION_PRICE_CSV_IMPORT_INVALID_ROOM_TYPE: '不正な施設・部屋タイプのIDが含まれています',
  ACCOMMODATION_PRICE_CSV_PARSE_FAILED: '宿泊料金CSVの読み込みに失敗しました。',
  ACCOMMODATION_PRICE_IMPORT_ALREADY_SCHEDULED: '既にスケジュールされている部屋タイプ・日付の組み合わせが含まれています',
  ACCOMMODATION_PRICE_IMPORT_NOT_FOUND: '存在しない取り込みファイルです',
  ACCOMMODATION_PRICE_IS_ALREADY_CANCELLED: '既にキャンセルされている読み込みです',
  ACCOMMODATION_PRICE_IS_ALREADY_IMPORTED: '既に取り込まれているためキャンセルできません',
  ACCOMMODATION_PRICE_NOT_ENOUGH: 'ご希望の日程は現在ご利用いただけません',
  AFTER_TRANSACTION_POINTS_SHOULD_BE_MORE_THAN_0: '保有TVPが不足しているため指定の操作を実行できません',
  ALLOWED_ONLY_FOR_FUTURE_BOOKINGS: '当日の予約は編集することができません',
  ANNUAL_CONTRACT_POINT_NOT_FOUND: '追加購入の年度に有効な契約ポイントが存在しないため処理を完了できません。',
  AVAILABLE_DAY_OF_WEEK_IS_FALSE: '指定のチケットでは利用できない曜日が含まれます',
  AVAILABLE_HOLIDAY_IS_FALSE_AND_EXISTS: '指定のチケットは宿泊利用ができません',
  BLOCK_PARKING_INVALID_PARKING_NUMBER: '駐車場ブロック枠数が不正です',
  BLOCK_RENTAL_ITEM_RESERVATION_INVALID_QUANTITY: '数量の指定が不正です',
  BOOKING_ACCOMMODATION_FEE_NOT_SET: 'ご指定の期間は現在ご利用いただけません',
  BOOKING_ADDITIONAL_CHARGE_NOT_FOUND: '存在しない請求項目です',
  BOOKING_ALREADY_CANCELLED: '既にキャンセルされた予約です',
  BOOKING_CAN_NOT_BE_CANCELLED: 'OTA予約はOTA上でのみキャンセルすることができます',
  BOOKING_CANCEL_DUPLICATED_STAY_DATE: 'キャンセル日に重複があります',
  BOOKING_CANCEL_STAY_DATE_OUT_OF_RANGE: 'キャンセル日が宿泊期間外です',
  BOOKING_CANNOT_READ: '当該予約の作成者でないため閲覧することができません',
  BOOKING_CHARGE_ALREADY_INVOICED: '既に請求書に含まれているため更新できません',
  BOOKING_CHARGE_EXTERNALLY_PAID: 'システム外で支払われた請求項目が選択されています',
  BOOKING_CHARGE_NOT_JPY: 'ポイントの請求項目は更新できません',
  BOOKING_DAYS_INPUT_IS_REQUIRED: '宿泊日の指定がありません',
  BOOKING_DELETE_NOT_CANCELLED: 'キャンセルされた予約のみ削除ができます',
  BOOKING_DOES_NOT_BELONG_TO_CURRENT_CLIENT: '指定の予約を編集する権限がありません',
  BOOKING_EXTENSION_CHECK_PRICE_NOT_AVAILABLE: '指定の予約は延泊ができません',
  BOOKING_EXTENSION_FIRST_DATE_IS_INVALID: '不正な延泊の宿泊日指定です',
  BOOKING_FROM_DATE_IS_NOT_AFTER_STAY_DATE: '指定のチケットでは利用できない日程です',
  BOOKING_GROUP_DOES_NOT_BELONG_TO_CURRENT_CLIENT: '指定の予約を編集する権限がありません',
  BOOKING_GROUP_IN_BOOKING_NOT_FOUND: '指定の予約グループに１件も予約が含まれていません',
  BOOKING_GROUP_NOT_FOUND: '存在しない予約グループです',
  BOOKING_INVALID_INVENTORY_TYPE: 'ポイント提携予約ではメンバー公開枠のみ指定できます',
  BOOKING_INVOICE_NOT_FOUND: '存在しない請求書です',
  BOOKING_NOT_FOUND: '存在しない予約です',
  BOOKING_NOT_FOUND_FOR_RENTAL_ITEM_RESERVATION: '存在しない予約です',
  BOOKING_NOT_TICKET_TYPE: 'チケット予約ではありません。',
  BOOKING_PLAN_CHARGE_NOT_ENOUGH_ERROR: 'プランの特殊除外日を含む日程のため利用できません',
  BOOKING_PLAN_CSV_PARSE_FAILED: 'プラン価格CSVの読み込みに失敗しました。',
  BOOKING_PLAN_MAX_DAYS_COUNT_ERROR: 'プランの最大宿泊日数を超えるため利用できません',
  BOOKING_PLAN_MAX_GUESTS_COUNT_ERROR: 'プランの最大利用人数を超えるため利用できません',
  BOOKING_PLAN_MAX_GUESTS_LIMIT_ERROR: 'プランの最大利用人数を超えたため予約を受け付けておりません',
  BOOKING_PLAN_MIN_DAYS_COUNT_ERROR: 'プランの最低宿泊日数に満たないため利用できません',
  BOOKING_PLAN_MIN_GUESTS_COUNT_ERROR: 'プランの最低利用人数に満たないため利用できません',
  BOOKING_PLAN_NOT_AVAILABLE_FOR_SELECTED_DAYS: '指定のプランは指定された宿泊日で使用できません',
  BOOKING_PLAN_NOT_FOUND: '存在しないプランです',
  BOOKING_PLAN_ROOM_COUNT_LIMIT_ERROR: 'プランの最大利用部屋数を超えたため予約を受け付けておりません',
  BOOKING_PLAN_STOP_SALE_DATE_ERROR: '販売が終了したプランです',
  BOOKING_PLAN_USED: '現在利用されているプランのため削除できません',
  BOOKING_TICKET_IS_ALREADY_USED: '既に利用されたチケットです',
  BOOKING_TICKET_NOT_AVAILABLE: '無効化されているチケットです',
  BOOKING_TICKET_NOT_FOUND: '存在しないチケットです',
  BOOKING_TICKET_TYPE_NOT_FOUND: '存在しないチケット種別です',
  BOOKING_TYPE_PLAN_TARGET_TYPE_NOT_MATCH: '指定のプランは指定の契約で利用することはできません',
  BOOKING_WAIT_LIST_CANCEL_ALREADY_NOTIFIED: '既に通知されたキャンセル待ちのため削除できません',
  BOOKING_PLAN_NOT_SUPPORTED_CONTRACT_PRODUCT_TYPE: 'ご指定の契約はプランでの予約に対応しておりません',
  BOOKING_WAIT_LIST_CREATE_DUPLICATED: '既に同様の通知が登録されています',
  BOOKING_IN_THE_PAST: '過去の予約に対して変更は受け付けておりません',
  BUSINESS_TYPE_NOT_FOUND: '存在しない業種です',
  CALCULATE_ACCOMMODATION_PRICE_NOT_DEFINED: '価格テーブルが未設定のため価格が計算できません',
  CAMPAIGN_APPLY_RESPONSE_NOT_FOUND: '存在しない活動履歴です',
  CAMPAIGN_NOT_FOUND: '存在しない広告施策です',
  CAN_NOT_CANCEL_OUTDATED_BOOKING_CHECK_IN: 'チェックイン日以降のキャンセルは施設へ電話連絡下さい',
  CAN_NOT_CANCEL_BOOKING_AFTER_SAME_DAY_15: '当日15時以降のキャンセルは施設へ電話連絡下さい',
  CAN_NOT_CHANGE_OUTDATED_BOOKING_CHECK_IN: 'チェックイン日以降の予約変更は施設へ電話連絡下さい',
  CAN_NOT_CHANGE_BOOKING_AFTER_SAME_DAY_15: '当日15時以降の予約変更は施設へ電話連絡下さい',
  CANCEL_WAIT_LIST_NOT_FOUND: '存在しないキャンセル待ちです',
  CHECK_IN_DATE_NOT_BETWEEN_AVAILABLE_DATES: '指定のチケットでは利用できない日程です',
  CLIENT_ACTION_HISTORY_NOT_FOUND: '存在しない活動履歴です',
  CLIENT_ANNUAL_FEE_NOT_FOUND: '存在しない年会費です',
  CLIENT_BOOKING_DATA_MISMATCH: '予約グループに含まれる全ての予約の情報を入力する必要があります',
  CLIENT_BOOKING_INSUFFICIENT_PERMISSIONS: '指定の予約を編集する権限がありません',
  CLIENT_BOOKING_TEMPORARY_EXPIRED: '仮予約の期限が切れたため予約を確定できません',
  CLIENT_CHARGE_NOT_FOUND: '存在しない顧客請求です',
  CLIENT_CHARGE_TYPE_NOT_FOUND: '存在しない顧客請求タイプです',
  CLIENT_COMMENT_NOT_FOUND: '存在しないコメントです',
  CLIENT_COMMENT_NOT_OWNED_BY_STAFF: '指定のコメントを編集する権限がありません',
  CLIENT_CONTACT_NOT_FOUND: '存在しない連絡先です',
  CLIENT_CREATE_BOOKING_GROUP_BOOKING_FOR_PAST_DATE: '宿泊日は未来の日付を指定して下さい',
  CLIENT_CREATE_BOOKING_GROUP_BOOKING_TOO_EARLY: '予約受付期間外です',
  CLIENT_CREATE_BOOKING_GROUP_NEED_ONE_ADULT: '少なくとも1部屋1名大人の宿泊者が必要です',
  CLIENT_CREATE_BOOKING_GROUP_NO_ROOMS: '部屋数を指定して下さい',
  CLIENT_CREATE_BOOKING_GROUP_SAME_DAY_BOOKING: '当日の予約はWebからは受け付けておりません。コールセンターへご連絡下さい',
  CLIENT_CREATE_BOOKING_GROUP_SAME_DAY_BOOKING_AFTER_15: '当日15時以降の予約はWebからは受け付けておりません。コールセンターへご連絡下さい',
  CLIENT_CREATE_BOOKING_GROUP_NEXT_DAY_AFTER_17: '翌日の予約は17時までとなりますので、翌日コールセンターへご連絡下さい。<br>※コールセンターが休業の場合は直接施設へお問い合わせください。',
  CLIENT_CREATE_BOOKING_GROUP_BOOKING_EARLY_WAIT_UNTIL_07: 'ご指定の日程は本日午前7時より受付開始となります。お時間になりましたら再度ご予約いただけますようよろしくお願い致します。',
  MOVE_EARLY_WAIT_UNTIL_07: 'ご指定の日程は本日午前7時より受付開始となります。お時間になりましたら再度お手続きいただけますようよろしくお願い致します。',
  MOVE_NOT_SUPPORTED_AFTER_SAME_DAY_15: 'チェックイン当日15時以降の日程変更はWebからは受け付けておりません。コールセンターへご連絡下さい',
  MOVE_NOT_SUPPORTED_AFTER_CHECKINDATE: 'チェックイン当日以降の日程変更はWebからは受け付けておりません。コールセンターへご連絡下さい',
  MOVE_NOT_SUPPORTED_AFTER_CHECKOUTDATE: 'チェックイン当日以降の日程変更はWebからは受け付けておりません。コールセンターへご連絡下さい',
  MOVE_TO_PAST_NOT_SUPPORTED: '過去の日付への日程変更はできません',
  MOVE_TO_SAME_DAY_AFTER_15_NOT_SUPPORTED: 'チェックイン当日15時以降の日程変更はWebからは受け付けておりません。コールセンターへご連絡下さい',
  CLIENT_CREATE_BOOKING_GROUP_TOO_MANY_GUESTS: '部屋タイプの最大客数を超えています',
  CLIENT_CREATE_BOOKING_GROUP_UNSUPPORTED_FACILITY: '指定の部屋タイプは予約を受け付けておりません',
  OVERLAPPING_BOOKINGS_NOT_ALLOWED: '既に同日程で別施設の宿泊予約が存在します。同一日程をキャンセルの上お申込ください。',
  CLIENT_HAS_NEITHER_GMO_NOR_TOP: '登録カード・TOPカード共に未登録の顧客です',
  CLIENT_LOGIN_NO_VALID_CONTRACT: '契約が存在しないか満了を迎えているためログインできません。',
  CLIENT_LOGIN_NOT_ALLOWED_VS_FOR_SUB_MEMBERS: 'バケーションスタイルWebにはログインが行えないユーザです。',
  CLIENT_NOT_DELETE_DUPLICATED_TARGET_CONTRACT: '既に契約が存在するため重複処理を実行できません',
  CLIENT_NOT_DELETE_DUPLICATED_TARGET_TYPE: 'VS資料請求顧客のみ重複処理が実行できます',
  CLIENT_NOT_FOUND: '存在しない顧客です',
  CONFIRM_PIC_ASSIGNMENTS_EMPTY: '担当割り振り結果を指定して下さい',
  CONTENT_TYPE_NOT_SUPPORTED: '指定のファイル形式はアップロードできません',
  CONTRACT_ADD_SIGNUP_BONUS_MORE_THAN_3: '最大3つまで契約特典を追加できます',
  CONTRACT_ALREADY_SUSPENDED: '既に停止済みの契約です',
  CONTRACT_ALREADY_UNSUSPENDED: '指定の契約は停止状態ではありません',
  CONTRACT_ATTACHMENT_NOT_FOUND: '存在しない契約関連ファイルです',
  CONTRACT_NOT_ALLOW_FOR_POINT_EXCHANGE: 'ポイント交換で使用できない契約です',
  CONTRACT_NOT_ALLOWED_FOR_TICKET_BOOKING: 'チケット予約では契約を指定することはできません',
  CONTRACT_NOT_FOUND: '存在しない契約です',
  CONTRACT_NOT_MW: '指定の契約はMWではありません',
  CONTRACT_NOT_PW: '指定の契約はPWではありません',
  CONTRACT_NOT_VM: '指定の契約はVMではありません',
  CONTRACT_POINT_NOT_FOUND: '存在しない契約ポイントです',
  CONTRACT_POINTS_NOT_ENOUGH: 'ポイント不足のため指定の操作を行うことができません',
  CONTRACT_POINTS_NOT_FOUND: '処理の対象となる契約ポイントが存在しません',
  CONTRACT_TRANSFER_ALREADY_EXPIRED: '既に満了している契約です',
  CONTRACT_TRANSFER_ALREADY_TERMINATED: '既に解約された契約です',
  CONTRACT_TRANSFER_ALREADY_TRANSFERRED: '既に譲渡・相続された契約です',
  CONTRACT_TRANSFER_UNCANCELLED_BOOKING_EXISTS: '未キャンセルの予約が存在するため指定の操作を行うことができません',
  CONTRACT_TYPE_NEITHER_SP_NOR_FP: 'シェアリングポイント・フレックスポイントの契約のみ行える処理です',
  CREATE_BOOKING_BOOKING_TYPE_NOT_FOUND: '存在しない予約タイプです',
  CREATE_BOOKING_CLIENT_NOT_FOUND: '存在しない顧客です',
  CREATE_BOOKING_CONTRACT_CLIENT_AND_CREATED_CLIENT_NOT_MATCH: '不正な予約作成者顧客IDです',
  CREATE_BOOKING_CONTRACT_NOT_SUPPORTING_POINT_BOOKING: 'ポイント提携予約に利用できない契約が指定されています',
  CREATE_BOOKING_INVALID_CLIENT_ID: '不正な代表者顧客IDです',
  CREATE_BOOKING_INVALID_CREATED_SUB_MEMBER_ID: '不正な予約作成者付属会員IDです',
  CREATE_BOOKING_INVALID_SUB_MEMBER_ID: '不正な代表者付属会員IDです',
  CREATE_BOOKING_NO_BOOKING_IN_THE_GROUP: '予約グループに予約が存在しません',
  CREATE_BOOKING_TOO_MANY_GUESTS: '部屋タイプの最大客数を超えています',
  CREATE_BOOKING_TRIAL_WITHOUT_CLIENT_ID: '体験宿泊は顧客IDを指定する必要があります',
  CREATE_BOOKING_UNSUPPORTED_BOOKING_TYPE: '指定の予約タイプで予約を作成することはできません',
  CREATE_BOOKING_UNSUPPORTED_CONTRACT: '新規予約を作成できない契約タイプです',
  CREATE_VM_BOOKINGS_INVALID_BOOKINGS_PRICE: '権利額が未設定の日付が存在します',
  CREATE_VM_BOOKINGS_INVALID_BOOKINGS_YEARS: 'バケーションマスターの年数と予約数が一致しません',
  CREATE_VM_TENTATIVE_BOOKINGS_INVALID_BOOKINGS_YEARS: 'バケーションマスター仮押さえの年数と予約数が一致しません',
  CREDIT_CARD_NOT_FOUND: 'クレジットカードが未登録です',
  CSV_CHARGE_TYPE_IS_NOT_SAME_AS_BOOKING_PLAN_CHARGE_TYPE: '不正な価格設定ファイルフォーマットです',
  CSV_HEADER_IS_EMPTY: 'CSVのヘッダ行が存在しません',
  CSV_HEADER_VALIDATION_FAILED: 'CSVのヘッダ行の形式が不正です',
  DAYS_OF_WEEK_MORE_THAN_7: '曜日の指定が不正です',
  DAYS_VM_BOOKING_DATES_NOT_EQUAL: '枠属性未設定の宿泊日が存在します',
  DELETE_ANNUAL_FEE_ALREADY_PROCESSED: '既に処理済みのため削除できません',
  DELETE_CONTRACT_NOT_TENTATIVE: '仮契約以外の契約は削除できません',
  DELETE_SIGNUP_BONUS_APPLICATION_ALREADY_HANDLED: '既に対応済みのため削除できません',
  DEPARTMENT_NOT_FOUND: '存在しない部署です',
  DIFFERENCE_DAYS_BOOKING_FROM_DAYS_ERROR: '指定のチケットの予約可能時期外です',
  DUPLICATED_STAFF_GROUP_NAME: '既に存在するグループ名です',
  EMAIL_ALREADY_REGISTERED_FOR_CLIENT_LOGIN: '既に利用されているメールアドレスです',
  EMAIL_TEMPLATE_NOT_FOUND: '存在しないメールテンプレートです',
  EMAIL_VERIFICATION_ALREADY_USED: '既に使用されたVacation Style会員登録URLです',
  EMAIL_VERIFICATION_INVALID_TOKEN: '無効なVacation Style会員登録URLです',
  EMAIL_VERIFICATION_NOT_FOUND: '無効なVacation Style会員登録URLです',
  EMAIL_VERIFICATION_TOKEN_EXPIRED: 'メール認証の有効期限が切れています。再度お試し下さい',
  END_DATE_LESS_THAN_START_DATE: '終了日より開始日が後になっています',
  EXTENSION_NOT_SUPPORTED: '指定の予約は延泊ができません',
  FACILITY_DAILY_REPORT_ALREADY_HQ_CONFIRMED: '既に承認済みです',
  FACILITY_DAILY_REPORT_ALREADY_SUBMITTED: '既に提出済みの日報です',
  FACILITY_DAILY_REPORT_NOT_FOUND: '存在しない日報です',
  FACILITY_DAILY_REPORT_TYPE_IS_NOT_CNV: '日報の形式がコンバージョン施設用ではありません',
  FACILITY_DAILY_REPORT_TYPE_IS_NOT_TVS: '日報の形式が直営施設用ではありません',
  FACILITY_HOTEL_TAX_AMOUNT_NOT_FOUND: '存在しない宿泊税料金です',
  FACILITY_HOTEL_TAX_NOT_FOUND: '存在しない宿泊税設定です',
  FACILITY_ID_DOES_NOT_BELONG_TO_BOOKING: '指定の駐車場は本予約では利用できません',
  FACILITY_ID_MISMATCH: '指定の施設情報の参照権限がありません',
  FACILITY_ID_NOT_SAME: '不正な施設IDです',
  FACILITY_IS_NOT_TVS: '直営施設ではないため直営施設形式の日報は提出できません',
  FACILITY_NOT_FOUND: '存在しない施設です',
  FACILITY_PARKING_HEIGHT_REQUIRED: '車高制限があるため車高を入力する必要があります',
  FACILITY_PARKING_NOT_FOUND: '存在しない駐車場です',
  FACILITY_PARKING_RESERVATION_NOT_FOUND: '存在しないかキャンセル済みの駐車場予約です',
  FACILITY_PARKING_RESERVATION_TOO_LATE: '駐車場のご予約の受付は既に終了しております。宿泊施設へ直接ご連絡ください',
  FACILITY_PARKING_RESERVATION_TOO_LATE_SAME_DAY: '駐車場のご予約の受付は既に終了しております。宿泊施設へ直接ご連絡ください',
  FACILITY_PARKING_WAITLIST_NOT_FOUND: '存在しない駐車場キャンセル待ちです',
  FACILITY_REPORT_NOT_ALLOWED_DATE: '現在選択できない日付です',
  FACILITY_TYPE_IS_NOT_CNV: 'コンバージョン施設ではないためコンバージョン施設形式の日報は提出できません',
  FACILITY_TYPE_PT_PARTIAL_CANCEL_NOT_ALLOWED: 'ポイント提携施設予約は部分キャンセルができません',
  FACILITY_WAIT_LIST_CREATE_DUPLICATED: '既にキャンセル待ちは設定済みです',
  FILE_IS_EMPTY: 'ファイルが空です',
  FILE_MAX_SIZE_EXCEEDED: '最大ファイルサイズを超えるためアップロードできません',
  FILE_UPLOAD_RULE_NOT_FOUND: 'アップロードタイプが不正です',
  FIRST_BOOKING_NOT_FOUND: '分割元の予約が存在しないかキャンセルされています',
  FIRST_DATA_SHOULD_BE_REPRESENTATIVE: '代表者は大人である必要があります',
  FROM_DATE_IS_NOT_IN_BOOKING_DATE_RANGE: '駐車場キャンセル待ちは宿泊日の範囲でのみ行えます',
  GET_DAILY_REPORT_FACILITY_ID_REQUIRED: '日報ID若しくは施設IDを指定して下さい',
  IN_BOOKING_PLAN_EXCEPTION_RANGE_ERROR: 'プランの除外日を含む日程のため利用できません',
  INVALID_BOOKING_CONTRACT: '宿泊日以前に終了する契約のため予約を行うことができません',
  INVALID_CLIENT_ON_BOOKING: '存在しない予約です',
  INVALID_CLIENT_REFRESH_TOKEN: '無効なリフレッシュトークンです',
  INVALID_CONTRACT_ID_FOR_AVAILABLE_FACILITY_LIST: '新規予約に利用できない契約です',
  INVALID_DATE_YEAR_MONTH: '年月の値が不正です',
  INVALID_EMAIL: 'メールアドレスが不正です',
  INVALID_MEDIA_TYPE: '不正な広告媒体です',
  INVALID_PASSWORD: 'パスワードに誤りがあります。ご確認の上再度お試し下さい',
  INVALID_STAFF_ASSIGNMENT: '不正な社員IDが含まれています',
  INVALID_STAFF_REFRESH_TOKEN: '不正なリフレッシュトークンです',
  INVALID_TICKET_BOOKING_DATES: 'チケット予約の状態が不正です',
  INVALID_TICKET_BOOKING_PENALTY_RATE: 'チケット予約のキャンセルリクエスト形式が不正です',
  INVALID_TIME: '時刻の形式が不正です',
  INVALID_VERIFICATION_TOKEN: 'トークンが不正です',
  INVALID_VS_PROMO_CODE: '不正な特典コードの形式です',
  INVENTORY_TYPE_NOT_FOUND: '存在しない枠属性です',
  INVOICE_ALREADY_PAID_CHARGE: '既に請求書が作成済みの請求項目が選択されています',
  INVOICE_DELETE_ALREADY_FINALIZED: '既に支払登録された請求書は削除できません',
  INVOICE_EXTERNALLY_CHARGED: 'システム外で支払われた請求項目が選択されています',
  INVOICE_INVALID_BOOKING_GROUP_CHARGE_COMBINATION: '不正な請求項目が選択されています',
  INVOICE_INVALID_PAYMENT_METHOD_COMBINATION: '不正な支払い方法の組み合わせです',
  INVOICE_MULTIPLE_REGISTERED_CARD_PAYMENTS: '登録カード払いは1つのみ登録が可能です',
  INVOICE_NO_CHARGE_SPECIFIED: '最低1つの請求項目を指定して下さい',
  INVOICE_NO_PAYMENT_SPECIFIED: '最低1つの支払い情報が必要です',
  INVOICE_NON_JPY_CHARGE: '日本円の請求項目のみ請求書に含めることができます',
  INVOICE_NOT_FOUND: '存在しない請求書です',
  INVOICE_PAYMENT_SUM_NOT_MATCH: '指定の支払い方法の合計額と選択された請求項目の合計額が一致しません',
  INVOICE_PDF_FILE_IS_NOT_SET: '請求書PDFが未作成状態です',
  INVOICE_REGISTERED_CARD_FOR_NON_CLIENT: '紐づく会員が存在しないため登録カード払いは使用できません',
  INVOICE_TVP_FOR_NON_CLIENT: '紐づく会員が存在しないためTVP払いは使用できません',
  INVOICE_UPDATE_ALREADY_FINALIZED: '既に支払登録された請求書は更新できません',
  MANUAL_CLIENT_CHARGE_CREATE_NO_GMO_CARD_REGISTERED: '登録カードが存在しない顧客です',
  MANUAL_CLIENT_CHARGE_CREATE_NO_TOP_CARD_REGISTERED: 'TOPカードが未登録の顧客です',
  MEMBER_USER_EXIST_EMAIL: '既に利用されているメールアドレスです',
  MEMBER_USER_NOT_FOUND: '顧客のメールアドレスが未登録状態です',
  MW_AFTER_DEPOSIT_DEADLINE: '交換期限を過ぎでいるため交換予約を行うことができません',
  MW_ALREADY_DEPOSITED: '既にデポジットされている権利です',
  MW_DEPOSIT_EXPIRE_DATE_NOT_FOUND: 'マイウィークカレンダー設定不足のためデポジット失効日が決定できません。',
  MW_INVALID_DEPOSIT_REQUEST_FIRST_OR_LATTER: 'デポジット対象を指定して下さい',
  MW_INVALID_SPLIT: 'マイウィーク予約分割のチェックイン日が不正です',
  MW_NEW_BOOKING_RANGE: '元権利の前後1年間の範囲でのみ交換予約が可能です',
  MW_NO_DEPOSITED_RIGHT: '交換に利用できるデポジット済みの権利がありません',
  MW_NO_UPGRADE: '契約マイウィークランク以上の施設のため交換することができません',
  MW_OPERATION_RIGHT_ALREADY_EXCHANGED: '既に交換に利用されているため処理を完了できません',
  MW_OPERATION_RIGHT_ALREADY_REVOKED: '既に無効化されている権利です',
  MW_OPERATION_RIGHT_EXPIRED: '期限切れのため処理を完了できません',
  MW_OPERATION_RIGHT_NOT_DEPOSITED_YET: 'デポジット状態の権利のみ操作を行うことができます',
  MW_OPERATION_RIGHT_NOT_FOUND: '存在しないマイウィーク権利です',
  MW_SPLIT_ALREADY_CHECKED_IN: '既にチェックインされた予約は分割できません',
  MW_SPLIT_RIGHT_CANNOT_BE_DEPOSITED_AT_ONCE: '前後半で分割されている場合、それぞれ順番にデポジット操作を行って下さい',
  MW_WEEK_CALENDAR_NOT_FOUND: 'マイウィークカレンダーが未設定の施設のため交換を行うことができません。',
  MY_CONTRACT_NOT_FOUND: '存在しない契約です',
  NAME_INVALID_LENGTH: 'グループ名は最低2文字必要です',
  NON_NOTIFIED_WAITLIST_EXCEEDS_ALLOWED_NUMBERS: '設定可能な最大数を超えるため駐車場キャンセル待ちは追加できません',
  NON_PRODUCT_CSV_IMPORT_INVALID_FILE_FORMAT: '商品外料金CSVの形式が不正です',
  NON_PRODUCT_CSV_IMPORT_INVALID_ROOM_TYPE: '不正な部屋タイプIDが含まれています',
  NON_PRODUCT_CSV_PARSE_FAILED: '商品外料金CSVの読み込みに失敗しました。',
  NON_PRODUCT_NOT_FOUND: '存在しない商品外品目です',
  NON_VS_AUTO_RENEWAL_NOT_ALLOWED: 'バケーションスタイル以外の契約では自動更新は設定できません',
  NOT_A_CNV_FACILITY_REPORT: 'コンバージョン施設日報ではありません',
  NOT_A_TVS_FACILITY_REPORT: '直営施設日報ではありません',
  NOT_FOUND_WEB_NOTIFICATION: '存在しないWeb通知です',
  NOT_IN_BOOKING_PLAN_ACCEPTANCE_DATE_RANGE_ERROR: 'プランの予約受付期間外のため利用できません',
  NOT_IN_BOOKING_PLAN_AVAILABLE_RANGE_ERROR: 'プランの利用日以外の日程を含むため利用できません',
  NOT_IN_BOOKING_PLAN_THROUGH_DATE_RANGE_ERROR: 'プランの予約受付日数以前のため利用できません',
  NOT_IN_BOOKING_PLAN_WEEKDAYS_RANGE_ERROR: 'プランが利用できない曜日を含む日程のため利用できません',
  NUMBER_OF_GUESTS_SHOULD_BE_MORE_THAN_0: '最低1人の宿泊客を指定して下さい',
  OTHER_PUBLIC_DOC_NOT_FOUND: '存在しないファイルです',
  OUT_OF_INVENTORY_STOCK: '指定の部屋タイプ・日程で在庫がありません。',
  OUT_OF_RANGE_POINTS: 'ポイント不足のため処理を完了できません',
  PARKING_DATE_NOT_IN_BOOKING: '日付は宿泊期間の範囲のみ設定可能です',
  PARKING_HEIGHT_BIGGER_THAN_MAX_HEIGHT: '車高制限以上の高さのため駐車場を利用できません',
  PARKING_RESERVATION_BIGGER_THAN_FACILITY_PARKING: '駐車場に空きが無いため予約を完了できませんでした',
  PARKING_RESERVATION_UPDATE_DATE_OUT_OF_STAY_RANGE: '不正な駐車場予約日付範囲です',
  PASSWORD_VERIFICATION_FAILED: 'パスワード認証に失敗しました',
  PAYMENT_METHOD_NOT_FOUND: '存在しない支払い方法です',
  PENALTY_RATES_INVALID_FORMAT: 'キャンセル日は宿泊日の最後の日から順番に指定して下さい',
  PENALTY_RATES_NOT_SPECIFIED: 'キャンセル率が指定されていません',
  PERSON_RELATIONSHIP_NOT_FOUND: '存在しない属柄です',
  POINT_BOOKING_FACILITY_NOT_PT: '指定された部屋タイプはポイント連携施設ではないため予約を作成できません',
  POINT_EXCHANGE_NOT_FOUND: '存在しないポイント交換です',
  POINT_EXCHANGE_PRODUCT_NOT_FOUND: '存在しないポイント交換商品です',
  POINTED_BOOKING_CHARGE_HAS_NO_CONTRACT: '予約に契約が紐付かないため更新できません',
  POINTED_BOOKING_CHARGE_INVALID_CONTRACT_TYPE: '予約に紐づく契約はポイント商品でないため更新できません',
  PRE_CHECKIN_MY_BOOKING_INVALID_GUEST_IDS: '宿泊者IDが不正です',
  PRICE_CALCULATE_SPECIFY_CONTRACT_OR_BOOKING_TYPE: '宿泊料計算のため契約IDか予約タイプを指定して下さい',
  PT_FACILITY_IS_NOT_FOR_REPOT: 'ポイント連携施設のため日報を作成できません',
  RECEIPT_NOT_FOUND: '存在しない領収書です',
  REGISTER_FIRST_ACCOUNT_ALREADY_EXISTS: '既にメールアドレス登録が完了しておりますので、そちらを利用してログインを行って下さい',
  REGISTER_FIRST_ACCOUNT_CLIENT_MAIN_CONTACT_NOT_FOUND: '入力内容に誤りがあります。内容をお確かめの上再度お試し下さい',
  REGISTER_FIRST_ACCOUNT_CLIENT_NOT_FOUND: '入力内容に誤りがあります。内容をお確かめの上再度お試し下さい',
  REGISTER_FIRST_ACCOUNT_TOKEN_EXPIRED: 'メールアドレス認証の有効期限を超えたため実行できません',
  RENTAL_ITEM_DATE_NOT_IN_BOOKING: '宿泊期間外の貸出期間を設定することはできません',
  RENTAL_ITEM_DEF_NOT_FOUND: '存在しない貸出品です',
  RENTAL_ITEM_NOT_FOUND: '存在しない貸出品です',
  RENTAL_ITEM_RESERVATION_BIGGER_THAN_RENTAL_ITEM_QUANTITY: '貸出品の在庫不足のため予約を行うことができません',
  RENTAL_ITEM_RESERVATION_NOT_FOUND: '存在しない若しくはキャンセル済みの貸出品予約です',
  RENTAL_ITEM_RESERVATION_TOO_LATE: '貸出品のご予約の受付は既に終了しております。宿泊施設へ直接ご連絡ください',
  RENTAL_ITEM_RESERVATION_TOO_LATE_SAME_DAY: '貸出品のご予約の受付は既に終了しております。宿泊施設へ直接ご連絡ください',
  REQUESTED_DATE_IS_NOT_CONSECUTIVE: '延泊の宿泊日は連続した日付である必要があります',
  REQUESTED_DATES_EMPTY: '延泊の宿泊日を指定して下さい',
  REQUIRED_AT_LEAST_1_TARGET_PRICE_TYPE: '少なくとも一つ更新対象の料金を指定して下さい',
  REQUIRED_ONE_DAY_FOR_TICKET_BOOKING: 'チケットでの予約は1日の宿泊のみ可能です',
  REQUIRED_TICKET_ID: 'チケットIDを指定して下さい',
  RESTRICT_FACILITY_ID_CHECK_FAILED: '指定データを変更する権限がありません',
  ROOM_ALLOCATION_BOOKING_ROOM_FIXED: '部屋割りが既に固定されているため変更できません',
  ROOM_ALLOCATION_NO_CHANGE_ROOM_TYPE: '異なる部屋タイプの部屋を割り振ることはできません',
  ROOM_NOT_AVAILABLE_FOR_EXTENSION_DATES: '当該予約の部屋に空きがないため延泊することができません',
  ROOM_NOT_FOUND: '存在しない部屋です',
  ROOM_OVERBOOKING: '指定日程で空きのある部屋が存在しません',
  NO_AVAILABLE_ROOM: '部屋割りの都合上、本日程では同部屋での連泊予約をお受けする事が出来ません。<br>1泊ずつのご予約をお試しください。',
  ROOM_CANNOT_ASSIGN_UNDEFINED: 'あいにく、本日程では同部屋の延泊予約をお受けする事が出来ません。<br>本日程でのご宿泊をご希望の方は、別途新規でのご予約をいただきますようお願い申し上げます。<br>※新規でのご予約では、商品に設定されたセットアップ料が発生いたします（一部商品を除く）。<br>※お部屋を移る際はチェックアウトおよび再度チェックインが必要となります。',
  ROOM_CANNOT_ASSIGN: 'あいにく、本日程では同部屋の延泊予約をお受けする事が出来ません。<br>本日程でのご宿泊をご希望の方は、別途新規でのご予約をいただきますようお願い申し上げます。<br>※新規でのご予約では、商品に設定されたセットアップ料が発生いたします（一部商品を除く）。<br>※お部屋を移る際はチェックアウトおよび再度チェックインが必要となります。',
  ROOM_TYPE_AND_TICKET_TYPE_NOT_FOUND: '指定のチケットで利用できない部屋タイプです',
  ROOM_TYPE_NOT_FOUND: '存在しない部屋タイプです',
  ROW_CSV_IMPORT_INVALID_EMAIL_FORMAT: '不正なメールアドレスが含まれています',
  ROW_CSV_IMPORT_INVALID_ENUM_FORMAT_COMPANY: '不正な個人法人区分が含まれています',
  ROW_CSV_IMPORT_INVALID_ENUM_FORMAT_GENDER: '不正な性別が含まれています',
  ROW_CSV_IMPORT_INVALID_ENUM_FORMAT_PRODUCT: '不正な希望商品が含まれています',
  ROW_CSV_IMPORT_INVALID_FILE_FORMAT: 'CSVのファイル形式が不正です',
  ROW_CSV_IMPORT_INVALID_FILE_FORMAT_POSTCODE: '不正な郵便番号が含まれています',
  ROW_CSV_IMPORT_INVALID_FK_FORMAT_CAMPAIGN: '不正な広告施策IDが含まれています',
  ROW_CSV_IMPORT_INVALID_FK_FORMAT_PURCHASE_RATE: '不正な購入評価IDが含まれています',
  ROW_CSV_IMPORT_INVALID_FK_FORMAT_STAFF_ID: '不正な社員IDが含まれています',
  SALE_ITEM_NOT_FOR_SALE: '現在販売登録されていない販売品です',
  SALE_ITEM_NOT_FOUND: '存在しない販売品です',
  SALE_ITEM_PRICE_OVERRIDE_NOT_ALLOWED: '価格変更が許可されていない販売品です',
  SALE_ITEM_UPDATE_NOT_UPDATABLE: '指定の販売品は編集はできません',
  SET_HOLIDAY_DATES_NOT_OF_YEAR: '異なる年度の日付が含まれています',
  SEVERAL_TYPES_OF_ROOM_NOT_FOUND: '指定された部屋タイプが不正です',
  SIGNUP_BONUS_APPLICATION_NOT_FOUND: '存在しない契約特典適用です',
  SIGNUP_BONUS_NOT_FOUND: '存在しない契約特典です',
  STAFF_FACILITY_OWNERSHIP_ACCESS_DENIED: '指定施設情報の参照権限がありません',
  STAFF_GROUP_NOT_FOUND: '存在しない社員グループです',
  STAFF_NOT_FOUND: '存在しない社員です',
  STAFF_ROLE_NOT_FOUND: '存在しない役割です',
  STAFF_ROLE_NOT_INCLUDED: 'ロールが未設定のためログインできません',
  STAFF_ROLE_PERMISSION_NOT_FOUND: '存在しない権限です',
  STAY_DATE_IS_IN_EXCEPTION_DATE: '指定のチケットの除外日が含まれています',
  SUB_MEMBER_NOT_FOUND: '存在しない付属会員です',
  SUB_MEMBER_TYPE_NOT_FOUND: '付属会員のタイプが不正です',
  DELETE_SUBMEMBER_WITH_FUTURE_BOOKINGS: '当該ファミリー会員のIDで行われた予約が残っています。ファミリー会員を削除するには該当の予約をキャンセルする必要があります。',
  BELOW_SUB_MEMBER_MIN_AGE: '18歳未満は登録できません',
  SUBJECT_NOT_FOUND: '存在しない科目です',
  SUBMIT_CNV_FACILITY_REPORT_INVALID_BOOKING_ID: '日報の対象ではない予約が含まれます',
  SUGGEST_PIC_CLIENTS_CANDIDATESTAFFIDS_EMPTY: '担当者を少なくとも一人選択して下さい',
  SUGGEST_PIC_CLIENTS_INCREASE_MAX_CLIENTS: '担当数の上限を超えました。上限を増やして再度実行して下さい。',
  SUGGEST_PIC_CLIENTS_WEBREQUESTIDS_EMPTY: '担当者割り振り対象を指定して下さい',
  TARGET_ENTITY_ID_REQUIRED: 'ファイルの対象IDを指定する必要があります',
  THE_INVENTORY_TYPE_CANNOT_BE_CHANGED: '変更対象外の枠属性です',
  TICKET_HAS_BEEN_ALREADY_USED: '既に利用済みのチケットです',
  TICKET_IS_DISABLED: '無効化されたチケットです',
  TO_DATE_IS_NOT_IN_BOOKING_DATE_RANGE: '駐車場キャンセル待ちは宿泊日の範囲でのみ行えます',
  UNSUPPORTED_BOOKING_TYPE_SUPPLIED: '予約時備考の対象でない予約タイプです',
  UNSUPPORTED_CONTRACT_PRODUCT_TYPE_FOR_POINT_BOOKING: 'ポイント連携予約に利用できない契約です',
  UNSUPPORTED_MEDIA_TYPE: 'サポートされていない広告媒体です',
  UPDATE_BOOKING_GUESTS_INVALID_STAY_DATE: '宿泊期間外の宿泊日が指定されています',
  UPDATE_CONTRACT_POINT_DURATION_INVALID_DATES: '権利開始日は終了日の前である必要があります',
  UPDATE_PARKING_INVALID_DATE_RANGE: '不正な駐車場予約日付範囲です',
  UPDATE_STAFF_ROLE_NON_EXISTS_PERMISSION: '不正な権限設定が含まれています',
  UPLOAD_USER_GUIDE_DUPLICATED_YEAR: '既に同一年度で利用ガイドは登録済みです',
  UPLOAD_VACATION_LIFE_DUPLICATED_YEAR_MONTH: '既に同一年月でバケーションライフは登録済みです',
  USER_GUIDE_NOT_FOUND: '存在しない利用ガイドです',
  USER_IS_DISABLED: '指定のユーザは無効化されています',
  USER_NOT_FOUND: 'メールアドレスもしくはパスワードが違います',
  VACATION_LIFE_NOT_FOUND: '存在しないバケーションライフです',
  VICE_MEMBER_CANNOT_BE_CREATED_BY_CLIENT: '副会員の追加は現在受け付けておりません。コールセンターまでご連絡下さい',
  VM_CANCEL_STAY_RIGHT_ALREADY_REFUNDED: '既にポイント返還されたVM権利が含まれています',
  VM_CANCEL_STAY_RIGHT_ALREADY_REVOKED: '既に無効化されたVM権利が含まれています',
  VM_DELETE_TENTATIVE_BOOKINGS_NOT_TENTATIVE: '既に確定されたVM契約は削除できません',
  VS_APPLICATION_NOT_EXISTS: '存在しないバケーションスタイル入会申し込みです',
  VS_PROMO_CODE_EXISTS: '既に存在する特典コードです。再生成の上再度お試し下さい',
  VS_SIGNUP_CONFIRM_NON_DUPLICATION_CLIENT_HAS_VS: '指定のお客様は既にバケーションスタイル契約を保持しています',
  VS_SIGNUP_CONFIRM_NON_DUPLICATION_NOT_DUPLICATED: '指定の申込は重複と判断されていません',
  VS_SIGNUP_EMAIL_ALREADY_EXISTS: '既に利用されているメールアドレスのため利用できません',
  VS_SIGNUP_INSUFFICIENT_INPUT: '入力されていない必須項目があります。お確かめの上再度お試し下さい',
  VS_SIGNUP_PROMO_ENDED: 'ご指定の特典は適用期間外です',
  VS_SIGNUP_PROMO_NOT_FOUND: '存在しない特典コードです',
  WEB_NOTIFICATION_NOT_FOUND: '存在しないWeb通知です',
  WEB_REQUEST_CLIENT_EXISTS: '存在しない顧客です',
  WEB_REQUEST_CSV_PARSE_FAILED: '資料請求CSVの読み込みに失敗しました。',
  WEB_REQUEST_NOT_FOUND: '存在しないWeb申し込みです',
  VS_AUTO_RENEWAL_CANCEL_NOT_ALLOWED: '現在契約自動更新は解除できません',
  CLIENT_EMAIL_NOT_REGISTERED: 'ご入力のメールアドレスは見つかりませんでした。',
  INVALID_CC_INFO: 'ご入力頂いたクレジットカードの認証に失敗しました。内容をお確かめの上再度お試し下さい。',
  CC_TEMPORARILY_UNAVAILABLE: '決済処理に失敗しました。しばらく時間をあけて再度お試し下さい。',
  CC_INVALID_TOKEN: '決済処理に失敗しました。もう一度カード番号を入力してください。',
  BOOKING_CANNOT_MODIFY_BY_CLIENT_NOW: '当日の予約は変更することができません。',
  BOOKING_CANNOT_MODIFY_BY_CLIENT_AFTER_15: '当日15時以降の予約は変更することができません。',
  PRE_PAYMENT_BOOKING_EXCESS: '宿泊料を上回るポイント利用登録はできません。',
  PRE_PAYMENT_BOOKING_NEGATIVE_REMAINING: 'すでにポイントが使用されているため変更できません'
}

const defaultMessageByErrorCode = {
  NOT_FOUND_ERROR: 'お探しの情報は見つかりませんでした。',
  ACCESS_DENIED_ERROR: 'ご指定の情報へのアクセスが拒否されました。',
  BAD_CREDENTIAL_ERROR: 'パスワード認証に失敗しました。入力内容をお確かめの上再度お試し下さい。',
  BAD_INPUT_ERROR: '不正な入力内容があります。入力内容をお確かめの上再度お試し下さい。',
  INVALID_MFA_CODE_ERROR: 'ログインに失敗しました。再度最初からお試し下さい。',
  INVALID_CHANGE_PASSWORD_CODE_ERROR: 'パスワード変更に失敗しました。再度最初からお試し下さい。',
  UNAUTHORIZED_ERROR: 'ご指定の情報へのアクセス権限がありません。',
  UNEXECUTABLE_ERROR: 'ご指定の処理が完了できませんでした。'
}

export function translateGraphQLError (code, message) {
  return translations[message] ||
    defaultMessageByErrorCode[code] ||
    defaultMessage
}
