import AppLayout from '@/layout/AppLayout'
import i18n from '@/plugins/i18n'

const privateRouter = {
  path: '/home', // custom if not logged in -> to '/login'
  component: AppLayout,
  meta: { auth: true },
  whiteBackground: true,
  children: [
    {
      path: '',
      name: 'home',
      meta: { title: i18n.t('title.main') },
      whiteBackground: true,
      component: () => import('@/views/HomePage/HomePage')
    },
    {
      path: 'logout',
      name: 'logout',
      meta: { title: i18n.t('common.logout'), noCard: true, noPaymentMethod: true },
      component: () => import('@/views/Logout')
    },
    {
      path: 'news',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'home-news',
          meta: { title: i18n.t('title.news') },
          component: () => import('@/views/News/NewsList')
        },
        {
          path: ':id',
          name: 'home-news-detail',
          meta: { title: i18n.t('title.newsDetail') },
          component: () => import('@/views/News/NewsDetail')
        }
      ]
    },
    {
      path: 'contract',
      name: i18n.t('title.contract'),
      meta: {
        title: i18n.t('title.contract')
      },
      component: () => import('@/views/Contract/Contract.vue')
    },
    {
      path: 'contract-detail',
      name: 'home-contract-detail',
      meta: {
        title: i18n.t('title.contractDetail')
      },
      component: () => import('@/views/Contract/ContractDetail.vue')
    },
    {
      path: 'contract-request',
      name: 'home-contract-request',
      meta: {
        title: i18n.t('title.contractReq')
      },
      component: () => import('@/views/Contract/ContractRequest.vue')
    },
    {
      path: 'booking',
      meta: {
        title: i18n.t('title.booking')
      },
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'home-booking',
          meta: { title: i18n.t('reservationAndUsageHistory.reservationAndUsageHistory'), whiteBackground: true },
          component: () => import('@/views/Booking/Booking')
        },
        {
          path: 'reservation-details/:id',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: 'home-booking-reservation-details-detail',
              meta: { title: i18n.t('reservationAndUsageHistory.reservationDetails') },
              component: () => import('@/views/Booking/ReservationDetails')
            },
            {
              path: 'pre-check-in-info',
              name: 'home-booking-reservation-details-detail-pre-check-in-info',
              component: () => import('@/views/Booking/ReservationDetails/PreCheckInInformation')
            },
            {
              path: 'reservation-change',
              component: { render: c => c('router-view') },
              children: [
                {
                  path: '',
                  name: 'home-booking-reservation-details-detail-reservation-change',
                  meta: { title: i18n.t('reservationAndUsageHistory.reservationChange') },
                  component: () => import('@/views/Booking/ReservationDetails/ReservationChange')
                },
                {
                  path: 'change',
                  name: 'home-booking-reservation-details-detail-reservation-change-change',
                  meta: { title: i18n.t('reservationAndUsageHistory.reservationChange') },
                  component: () => import('@/views/Booking/ReservationDetails/ReservationChange/Change'),
                  children: [
                    {
                      // old route left for compatibility. should be removed in the future
                      path: 'accommodation-date-extension',
                      name: 'home-booking-reservation-details-detail-reservation-change-accommodation-date-extension',
                      meta: {
                        title: i18n.t('reservationAndUsageHistory.accommodationDateMove'),
                        onReservationDetailPage: true
                      },
                      component: () => import('../../components/ReservationComponents/AccommodationDateMove')
                    },
                    {
                      // old route left for compatibility. should be removed in the future
                      path: 'accommodation-date-move',
                      name: 'home-booking-reservation-details-detail-reservation-change-accommodation-date-move',
                      meta: {
                        title: i18n.t('reservationAndUsageHistory.accommodationDateMove'),
                        onReservationDetailPage: true
                      },
                      component: () => import('../../components/ReservationComponents/AccommodationDateMove')
                    },
                    {
                      path: 'guest-information',
                      name: 'home-booking-reservation-details-detail-reservation-change-guest-information',
                      meta: { title: i18n.t('reservationAndUsageHistory.changeGuestInformationChange') },
                      component: () => import('../../views/Booking/ReservationDetails/ReservationChange/GuestInformationChange')
                    },
                    {
                      path: 'rental-listing-change',
                      name: 'home-booking-reservation-details-detail-reservation-change-rental-listing-change',
                      meta: { title: i18n.t('reservationAndUsageHistory.changeRentalListingChange') },
                      component: () => import('../../views/Booking/ReservationDetails/ReservationChange/RentalItemChange')
                    },
                    {
                      path: 'parking-change',
                      name: 'home-booking-reservation-details-detail-reservation-change-parking-change',
                      meta: { title: i18n.t('reservationAndUsageHistory.changeParkingLotChange') },
                      component: () => import('../../views/Booking/ReservationDetails/ReservationChange/ParkingChange')
                    },
                    {
                      path: 'pet-change',
                      name: 'home-booking-reservation-details-detail-reservation-pet-change',
                      meta: { title: i18n.t('reservationAndUsageHistory.changePetInfoChange') },
                      component: () => import('../../views/Booking/ReservationDetails/ReservationChange/PetInfoChange')
                    },
                    {
                      path: 'tvp-change',
                      name: 'reservation-tvp-prepayment-change',
                      meta: { title: i18n.t('reservationAndUsageHistory.changeTVPPrePayment') },
                      component: () => import('../../views/Booking/ReservationDetails/ReservationChange/TVPPrePaymentChange')
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'confirmation/:id',
          name: 'home-booking-confirmation-detail',
          meta: { title: i18n.t('reservationAndUsageHistory.confirmation') },
          component: () => import('@/views/Booking/Confirmation')
        }
      ]
    },
    {
      path: 'contract-information',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'home-contract-information',
          meta: { title: i18n.t('title.listOfContracts') },
          component: () => import('@/views/ContractInformation')
        },
        {
          path: 'confirmation',
          name: 'home-contract-information-confirmation',
          meta: { title: i18n.t('common.contractAutomaticRenewalSetting') },
          component: () => import('@/views/ContractInformation/Confirmation')
        }
      ]
    },
    {
      path: 'download-documents',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'home-download-documents',
          meta: { title: i18n.t('downloadDocuments.downloadDocuments') },
          component: () => import('@/views/DownloadDocuments')
        },
        {
          path: 'vacation-life-back-number',
          name: 'home-download-documents-vacation-life-back-number',
          meta: { title: i18n.t('downloadDocuments.downloadDocuments') },
          component: () => import('@/views/DownloadDocuments/VacationLifeBackNumber')
        }
      ]
    },
    {
      path: 'member-info',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'member-info',
          meta: { title: i18n.t('menu.main.membershipInformation') },
          component: () => import('@/views/MemberInfo')
        },
        {
          path: 'contact',
          name: 'home-member-info-contact',
          meta: { title: i18n.t('memberInfo.changeContact') },
          component: () => import('@/views/MemberInfo/MemberInfoContact')
        },
        {
          path: 'mfa-policy',
          name: 'home-member-info-mfa-policy',
          meta: { title: i18n.t('memberInfo.changeMFAPolicy') },
          component: () => import('@/views/MemberInfo/MFAPolicy')
        },
        {
          path: 'credit-card',
          name: 'home-member-info-credit-card',
          meta: { title: i18n.t('title.changeCreditCardInfo'), noCard: true, noPaymentMethod: true },
          component: () => import('@/views/MemberInfo/MemberInfoCreditCard/UpdateCardInfo')
        },
        {
          path: 'new-credit-card',
          name: 'home-member-info-new-credit-card',
          meta: { title: i18n.t('title.changeCreditCardInfo'), noCard: true, noPaymentMethod: true },
          component: () => import('@/views/MemberInfo/MemberInfoCreditCard/NewCardRegister')
        },
        {
          path: 'receipt-list',
          name: 'home-member-info-receipt-list',
          meta: { title: i18n.t('title.receiptList') },
          component: () => import('@/views/MemberInfo/MemberInfoReceiptList')
        },
        {
          path: 'payment-method',
          name: 'home-member-info-payment-method',
          meta: { title: i18n.t('title.changePaymentInfo'), noPaymentMethod: true },
          component: () => import('@/views/MemberInfo/MemberInfoPaymentMethod')
        },
        {
          path: 'member',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: 'home-member-info-member',
              meta: { title: i18n.t('title.changeMembersInfo') },
              component: () => import('@/views/MemberInfo/MemberInfoMember')
            },
            {
              path: ':id',
              name: 'home-member-info-member-detail',
              meta: { title: i18n.t('title.changeMembersInfo') },
              component: () => import('@/views/MemberInfo/MemberInfoMember/MemberInfoMemberDetails')
            }
          ]
        },
        {
          path: 'introduction',
          name: 'home-member-info-introduction',
          meta: { title: i18n.t('common.introduction') },
          component: () => import('@/views/MemberInfo/MemberInfoIntroduction')
        }
      ]
    },
    {
      path: 'vacancy-search',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'home-vacancy-search',
          meta: { title: i18n.t('menu.main.vacancySearch') },
          component: () => import('@/views/VacancySearch')
        },
        {
          path: 'reservation',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: 'home-vacancy-search-reservation',
              meta: { title: i18n.t('menu.main.vacancySearch') },
              component: () => import('@/views/VacancySearch/Reservation')
            },
            {
              path: 'details/:id',
              name: 'home-vacancy-search-reservation-details-detail',
              meta: { title: i18n.t('title.reservationDetails') },
              component: () => import('@/views/VacancySearch/Reservation/Details')
            },
            {
              path: 'select-plan',
              name: 'home-vacancy-search-reservation-select-plan',
              meta: { title: i18n.t('title.selectPlan') },
              component: () => import('@/views/VacancySearch/Reservation/SelectPlan')
            },
            {
              path: 'confirmation',
              name: 'home-vacancy-search-reservation-confirmation',
              meta: { title: i18n.t('title.reservationConfirmation') },
              component: () => import('@/views/VacancySearch/Reservation/Confirmation')
            },
            {
              path: 'enter-information',
              name: 'home-vacancy-search-reservation-enter-information',
              meta: { title: i18n.t('title.reservationConfirmation'), keepTemporaryBooking: true },
              component: () => import('@/views/VacancySearch/Reservation/EnterInformation')
            },
            {
              path: 'final-confirmation',
              name: 'reserve-final-confirmation',
              meta: { title: i18n.t('title.reservationConfirmation'), keepTemporaryBooking: true },
              component: () => import('@/views/VacancySearch/Reservation/FinalConfirmation')
            },
            {
              path: 'complete',
              name: 'home-vacancy-search-reservation-complete',
              meta: { title: i18n.t('title.reservationComplete') },
              component: () => import('@/views/VacancySearch/Reservation/Complete')
            }
          ]
        }
      ]
    },
    {
      path: 'exchange-points',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'home-exchange-points',
          meta: { title: i18n.t('menu.main.giftTicket') },
          component: () => import('@/views/GiftTicket/GiftTicketExchangePoints')
        },
        {
          path: 'history',
          name: 'home-exchange-points-history',
          meta: { title: i18n.t('menu.main.items.giftTicketUsageHistory'), whiteBackground: true },
          component: () => import('@/views/GiftTicket/GiftTicketUsageHistory')
        },
        {
          path: 'order',
          name: 'home-exchange-points-order',
          meta: { title: i18n.t('menu.main.items.orderTickets'), whiteBackground: true },
          component: () => import('@/views/GiftTicket/GiftTicketOrder')
        }
      ]
    },
    {
      path: 'vacancy-notification',
      name: 'home-vacancy-notification',
      meta: { title: i18n.t('menu.main.vacancyNotification') },
      component: () => import('@/views/VacancyNotification')
    }
  ]
}

export default privateRouter
