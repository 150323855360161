import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './assets/styles/index.scss'
import i18n from './plugins/i18n'
import '@/permission'
import store from './store'
import { defaultApolloProvider } from './plugins/apollo'
import ShowGqlError from './plugins/showGqlError'
import filters from './filters'
import doLoading from './plugins/doLoading'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { getAddressString } from './utils/string'
import * as rules from '@/utils/rules'
import FormatSpan from '@/components/Common/FormatSpan'

Vue.config.productionTip = false

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000
})

Vue.use(ShowGqlError, {
  toast: Vue.prototype.$toast
})

Vue.use(doLoading)

Vue.use({
  install (Vue) {
    Vue.prototype.$rules = rules
  }
})
Vue.component('format-span', FormatSpan)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.use({
  install (Vue) {
    Vue.prototype.$isMainUser = function () {
      return this.$store.getters.isMainUser
    }
    Vue.prototype.$isSubUser = function () {
      return this.$store.getters.isSubUser
    }
    Vue.prototype.$isFamilyUser = function () {
      return this.$store.getters.isFamilyUser
    }
    Vue.prototype.$isCompanyUser = function () {
      return this.$store.getters.isCompanyUser
    }
    Vue.prototype.$isAllowed = function () {
      return {
        pointExchangeHistory: () => {
          return !this.$isFamilyUser() && (
            this.$isCompanyUser() || !this.$isSubUser()
          )
        }
      }
    }
    Vue.prototype.$getAddressString = getAddressString
    Vue.prototype.$reload = function () {
      router.go()
    }
    Vue.prototype.$isMobile = function () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
    Vue.prototype.$env = {
      isProd: process.env.NODE_ENV === 'production',
      isStg: process.env.NODE_ENV === 'staging',
      isDev: process.env.NODE_ENV === 'development'
    }
  }
})

new Vue({
  router,
  vuetify,
  apolloProvider: defaultApolloProvider,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
