<template>
  <v-app>
    <environment-banner />
    <router-view />
    <v-btn
      elevation="0"
      class="btn-scroll-top"
      v-scroll="onScroll"
      v-show="fab"
      fab
      white
      fixed
      bottom
      right
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import EnvironmentBanner from '@/components/Alert/EnvironmentBanner'

export default {
  name: 'App',
  components: { EnvironmentBanner },
  data () {
    return {
      fab: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    checkLastAction () {
      const lastAction = localStorage.getItem('lastAction') ? Number(localStorage.getItem('lastAction')) : null
      if (this.$store.getters.isLoggedIn) {
        if (lastAction) {
          const secondsSince = (Date.now() - lastAction) / 1000
          if (secondsSince > 3600) {
            let reason = ''
            if (sessionStorage.getItem('lastAction')) {
              // user has interacted with this tab at least once, so
              reason = 'inactive'
            }
            this.$router.push({ name: 'logout', query: { reason } })
          }
        }
      }
    }
  },
  mounted () {
    if (process.env.NODE_ENV === 'development') {
      // this.$store.commit('setToken', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZSI6Ik1BSU4iLCJjbGllbnRJZCI6MSwic3ViTWVtYmVySWQiOm51bGwsInR5cGUiOjEsImlhdCI6MTYzMTU5ODgwMiwiZXhwIjoxNjYzMTU2NDAyfQ.qjHK05bLLBXZmRrSJfqUr9gqlmK2NcFjl49IbNYS4ItUwKJRpY2BcCZAcgyZnfghCcCgdYjzD_OpPCtCOA38oIaiFaPRmFvenaI2ewI1OZ977Kt2Timhe-caDzFOGTFLYd3JCLDsndlWQnjVMzq2jZO6L7P7RoAAVuIrcPiMyXx6WaLde9JLX7bcY0Z7Hbb-fBGzCQ-VNtZrPm34yOGnHMYRJo_djNRa0_vieHVaEx6TFIk_tiLVpIF2ZZGzicI6CO82YBZpPTnSUbTmw0Hirvt5Rst1lH3xea6NguqNEp3cKQ1YZ6LBE6qckIuI5qLai1TZC2ZnOTMAKyJ0Uf6Mbg')
    }

    ['click', 'scroll', 'keydown', 'touchstart'].forEach(event => {
      document.addEventListener(event, () => {
        this.$store.dispatch('logLastAction')
      })
    })
    setInterval(() => {
      this.checkLastAction()
    }, 1000)
    this.checkLastAction()
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/styles/modules/variables-scss' as v;

::v-deep {
  .v-btn.btn-scroll-top {
    color: var(--color__primary) !important;
    z-index: 3 !important;
    @media (min-width: v.$max-width-screen) {
      color: var(--color__white) !important;
      background-color: var(--color__primary) !important;
    }
  }
}
</style>
