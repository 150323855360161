import gql from 'graphql-tag'

export const REQUEST_MFA_CLIENT = gql`
  mutation ($email: String!, $password: String!, $vsIntent: Boolean!) {
    requestMFAClient(email: $email, password: $password, vsIntent: $vsIntent) {
      token
      validUntil
      loginUrl
    }
  }
`
export const CONFIRM_MFA_CLIENT = gql`
  mutation ($token: String!, $code: String!) {
    confirmMFAClient(code: $code, token: $token) {
      accessToken,
      refreshToken,
      memberUserId,
      forcePasswordChangeRequired
    }
  }
`

export const START_PASSWORD_RESET = gql`
  mutation initiateReset($email: String!){
    requestResetPasswordClient(email: $email) {
      token
    }
  }
`

export const CONFIRM_PASSWORD_RESET = gql`
  mutation confirmReset($code: String!, $password: String!, $token: String!){
    confirmResetPasswordClient(
      code: $code, password: $password, token: $token) {
      accessToken
      refreshToken
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation refreshAccessToken ($refreshToken: String!) {
    refreshClientToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`

export const REGISTER_EMAIL_START = gql`
  mutation($email: String!) {
    submitEmailClientVerification(
      email: $email
    )
  }
`

export const REGISTER_FIRST_USER = gql`
  mutation (
    $token: String!
    $memberId: String!
    $birthday: DateString!
    $tel: String!
    $password: String!
  ) {
    submitTokenInfoClient(
      token: $token
      password: $password
      memberId: $memberId
      birthday: $birthday
      tel: $tel
    )
  }
`

export const GET_USER_INFO = gql`
  query {
    myUserInfo {
      name
      prefecture
      locality
      address1
      address2
      tel
      mobile
      email
      contactEmail
      birthday
      sex
    }
  }
`

export const REGISTER = gql`
  mutation ($email: String! $isCompany: Boolean! $promoCode: String)
  {
    vsSignupEmailVerification(
      email: $email
      isCompany: $isCompany
      promoCode: $promoCode
    )
  }
`

export const SIGNUP_TOKEN_INFO = gql`
  mutation ($token: String!){
    vsSignupTokenInfo(token: $token)
    {
      isCompany
      email
      promoCode
    }
  }
`

export const VALIDATE_PROMOCODE = gql`
  query ($promoCode: String!)
  {
    validateVsPromoCodeSignupBonus(promoCode: $promoCode)
    {
      vsPromoDiscount # the discount
    }
  }
`

export const VS_SIGNUP_SUBMIT_FORM = gql`
  mutation (
    $token: String!
    $password: String!
    $isCompany: Boolean!
    $postalCode: String!
    $prefecture: String!
    $locality: String!
    $address1: String!
    $address2: String!
    $tel: String
    $mobile: String # at least either tel or mobile is needed
    $creditCardToken: String!
    $promoCode: String
    $individualInfo: SubmitFormIndividualInput
    $companyInfo: SubmitFormCompanyInput
    $media: String!
    $clientCode: String
  ) {
    vsSignupSubmitForm(data: {
      token: $token
      isCompany: $isCompany
      clientCode: $clientCode
      password: $password
      postalCode: $postalCode
      prefecture: $prefecture
      locality: $locality
      address1: $address1
      address2: $address2
      tel: $tel
      mobile: $mobile
      creditCardToken: $creditCardToken
      promoCode: $promoCode
      individualInfo: $individualInfo
      companyInfo: $companyInfo
      media: $media
    }) {
      result # if this is "StaffWillContact", then show the page where it says so
    }
  }
`
