import PublicLayout from '@/layout/PublicLayout'
import i18n from '@/plugins/i18n'

const publicRouter = {
  path: '/',
  component: PublicLayout,
  redirect: '/home', // custom if logged -> to '/home'
  children: [
    {
      path: '/login',
      name: 'login',
      meta: { title: i18n.t('common.login'), isMiniFooter: true },
      component: () => import('@/views/Login')
    },
    {
      path: '/register',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'register',
          meta: { title: i18n.t('title.registerWithEmail'), hideHeader: true, isMiniFooter: true },
          component: () => import('@/views/Register')
        },
        {
          path: 'vs-signup',
          name: 'register-vs-signup',
          meta: { title: i18n.t('title.registerMemberInformation'), hideHeader: true, isMiniFooter: true },
          component: () => import('@/views/Register/MemberInformation')
        },
        {
          path: 'payment-method',
          name: 'register-payment-method',
          meta: { title: i18n.t('title.registerPaymentMethod'), hideHeader: true, isMiniFooter: true },
          component: () => import('@/views/Register/PaymentMethod')
        },
        {
          path: 'confirmation',
          name: 'register-confirmation',
          meta: { title: i18n.t('text.stage.confirmation'), hideHeader: true, isMiniFooter: true },
          component: () => import('@/views/Register/Confirmation')
        },
        {
          path: 'complete',
          name: 'register-complete',
          meta: { title: i18n.t('text.stage.complete'), hideHeader: true, isMiniFooter: true },
          component: () => import('@/views/Register/Complete')
        },
        {
          path: 'complete-please-wait',
          name: 'register-complete-wait',
          meta: { title: i18n.t('text.stage.complete'), hideHeader: true, isMiniFooter: true },
          component: () => import('@/views/Register/CompletePleaseWait')
        }
      ]
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      meta: { title: i18n.t('common.settingPass'), hideHeader: true },
      component: () => import('@/views/PasswordReset')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: { title: i18n.t('common.forgotPassTitle'), hideHeader: true },
      component: () => import('@/views/ForgotPassword')
    },
    {
      path: '/mfa-auth',
      name: 'mfa-auth',
      component: () => import('@/views/Login/Confirm')
    },
    {
      path: 'maintenance',
      name: 'maintenance',
      meta: { inMaintenance: true },
      component: () => import('@/views/Error/Maintenance')
    }
  ]
}

export default publicRouter
