<template>
  <div class="stg-alert" @mouseenter="hide" @mouseleave="show">
    <v-alert v-if="!$env.isProd" type="error" class="mb-0 pa-0 white-alert" :class="{hidden}" dense>
      <template v-if="$env.isStg">ステージング環境</template>
      <template v-else-if="$env.isDev">開発環境</template>
    </v-alert>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hidden: false,
      showAt: Date.now()
    }
  },
  methods: {
    hide () {
      this.hidden = true
      this.showAt = Date.now()
    },
    show () {
      const showAt = Date.now()
      this.showAt = showAt
      setTimeout(() => {
        if (this.showAt === showAt) {
          this.hidden = false
        }
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.stg-alert {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  .hidden {
    visibility: hidden;
  }
}
.white-alert {
  color: white !important;
  & ::v-deep .v-icon {
    color: white !important;
  }
}
</style>
