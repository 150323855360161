import actions from './actions'

const common = {
  state: {
    currentRequestsNumber: 0,
    isLoading: false,
    callCenterDialog: false
  },
  mutations: {
    setIsLoading (state, val) {
      if (val) state.currentRequestsNumber++
      else state.currentRequestsNumber--

      state.isLoading = state.currentRequestsNumber > 0
    },
    showCallCenterDialog (state, val) {
      state.callCenterDialog = val !== false
    }
  },
  actions
}

export default common
