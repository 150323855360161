export default {
  menu: {
    main: {
      vacationMemberPage: 'Vacation会員ページTOP',
      vacancySearch: '新規予約',
      contractDetails: '契約情報',
      reservationAndUsageHistory: '<space class="no-break">予約変更</span><span class="no-break"><span class="show-footer">・</span>予約履歴</span>',
      membershipInformation: '会員情報',
      giftTicket: 'ギフトチケット',
      exchangePoints: 'ポイント交換',
      vacancyNotification: '空室通知',
      items: {
        viceMemberAndFamilyMember: '副会員・家族会員',
        remainingPoints: 'ポイント残数',
        remainingDeposit: 'デポジット残数',
        expirationDate: '有効期限',
        purchaseAdditionalPoints: 'ポイント追加購入',
        reservationChange: '予約情報変更',
        cancelReservation: '予約キャンセル',
        changePassword: 'パスワード変更',
        changeMembershipInfo: '会員情報変更',
        deputyMemberAndFamilyMember: '副会員・家族会員',
        changeApplication: '変更申請',
        orderTickets: 'チケットを注文する',
        // -------------------- === ------------------------
        viewReceptionStatus: '受付状況を見る',
        receptionStatusOrHistory: '受付状況・履歴を見る',
        pointExchangeUsageHistory: 'ポイント交換利用履歴',
        giftTicketUsageHistory: 'ギフトチケット利用履歴'
        // -------------------- === ------------------------
      }
    },
    sub: {
      newsFromTokyuVacations: '東急バケーションズからのお知らせ',
      downloadUserGuide: 'ご利用ガイドダウンロード',
      vacationLifeBackIssues: 'バケーションライフバックナンバー'
    }
  },
  title: {
    registration: '登録',
    login: 'ログイン',
    forgotPassword: 'パスワード設定',
    resetPassword: 'パスワード設定',
    registerCreditCard: 'クレジットカードの登録',
    registerTicketReservation: 'チケット予約メールアドレス登録',
    changeCreditCardInfo: 'クレジットカード情報変更',
    receiptList: '領収書一覧',
    changePaymentInfo: '支払い情報変更',
    changeMembersInfo: 'ファミリー会員一覧',
    listOfContracts: '契約情報',
    contractInformationDetails: '契約情報詳細',
    reservationSearchResults: '予約-検索結果',
    membersOnlySite: {
      header: '会員様専用サイト',
      subHeader: {
        main: 'Vacation',
        desc: '会員ページ'
      }
    },
    pageNotFound: 'ページが見つかりません',
    membersOnlySiteLogin: '会員様専用サイトログイン',
    main: 'メイン',
    news: 'お知らせ',
    newsDetail: 'お知らせ 詳細',
    contract: '契約一覧',
    contractDetail: '契約一覧詳細',
    contractReq: '契約リストリクエスト',
    registerWithEmail: 'メールアドレス・パスワード登録',
    mostRecentBooking: '直近のご予約',
    booking: ' 予約履歴・利用履歴',
    registerNewMember: '新フローティング会員登録',
    vacancySearch: '空室検索',
    reservationDetails: '予約-詳細',
    reservation: '予約',
    selectPlan: '予約-プラン選択',
    reservationConfirmation: '予約-確認',
    reservationComplete: '予約-完了',
    registerMemberInformation: '会員情報の登録',
    registerPaymentMethod: '支払方法の登録'
  },
  messages: {
    validate: {
      emailIsRequired: 'メールアドレスは必須です。',
      emailMustBeValid: 'メールアドレスが無効な形式です。',
      passwordIsRequired: 'パスワードが必要です。',
      passwordRules: 'パスワードは８文字以上かつ、小文字・大文字・数字・記号のうち少なくとも２種類を含む必要があります。'
    }
  },
  authPage: {
    registerEmailAddress: 'メールアドレスのご登録をお願いいたします',
    registerEmailForTicket: 'メールアドレスをご登録ください',
    enterAgainToConfirm: '確認のためもう一度入力してください',
    pleaseCheckEmailAndConfirm: 'ご入力頂いたメールアドレス宛に案内を送付いたしましたのでご確認下さい',
    needsCreditCard: '引き続きご利用いただくには、新しいクレジットカード情報を登録いただく必要があります。',
    confirmEmail: {
      line1: '登録用のURLを送信いたしましたので',
      line2: 'メールに記載のURLより',
      line3: '登録手続きを進めてください'
    },
    enterTheInformation: '下記項目を入力してください',
    accountCreated: 'メールアドレスが登録されました。指定したパスワードを使用してログインしてください。',
    membershipNumber: '会員番号（ハイフンを含む）',
    membersLogInBelow: '会員様は下記よりログインいただけます',
    saveYourLoginID: 'ログインIDを保存する',
    forgotYourPassword: 'パスワードをお忘れの方',
    ticketUsers: 'チケット利用者様',
    loginFailed: {
      line1: 'ログインできません。',
      line2: 'ご入力いただいたアカウントは退会処理されております。',
      line3: '再度ご利用をご希望の場合はお問い合わせください。'
    },
    loggingInWait: 'ログインしています、少々お待ち下さい。',
    enterEmailToResetPassword:
      'パスワード再設定用のリンクをお送りしますので下記にメールアドレスを入力してください',
    linkResetHasBeenSent:
      'メールアドレスにパスワード設定用のリンクをお送りしましたので、メールをご確認いただき、パスワードを設定してください',
    enterNewPassword: '新しいパスワードを入力してください',
    resetPasswordSuccess:
      'パスワードの設定が完了しました。設定したパスワードでログインしてください',
    registerCreditCardNotice:
      '引き続きご利用いただくには、新しいクレジットカード情報を登録いただく必要があります。',
    securityCodeQuestion: '＊セキュリティコードとは？',
    checkMailSent:
      '登録いただいたメールアドレスにメール送信いたしましたのでご確認ください。'
  },
  common: {
    loginId: 'ログインID　※メールを受信できるメールアドレスを入力してください',
    japanNationalities: '日本国籍',
    japan: '日本',
    otherNationalities: 'その他国籍',
    other: 'その他',
    stand: '台',
    totalPeople: '合計人数',
    quantity: '個数',
    useTheSchedule: '利用日程',
    children1: '子ども',
    only: '才',
    residence: '住所',
    leaveNameUnset: 'ご指名未設定',
    childSleepWithOut: '子供 (添寝なし)',
    childSleepWith: '子供 (添寝あり)',
    restitution: '返還',
    individual: '個',
    noOverNight: '宿泊なし',
    display: 'を表示',
    none: 'なし',
    existence: 'あり',
    cancelled: 'キャンセル済',
    noAutoUpdate: '自動更新しない',
    autoUpdate: '自動更新する',
    makeContract: '契約を',
    notAutoRenewContract: '契約を自動更新しない',
    autoRenewContract: '契約を自動更新する',
    login: 'ログイン',
    valid: '有効',
    emailAddress: 'メールアドレス',
    password: 'パスワード',
    checkIn: 'チェックイン',
    homePrecheckin: '予約確認・変更',
    person: '名',
    adult: '大人',
    children: '子供',
    coSleeping: '添い寝あり',
    bedSharing: '添い寝なし',
    settingPass: 'パスワード再設定',
    resetPassComplete: 'パスワード再設定完了',
    pleaseEnterNewPass: '新しいパスワードを入力してください',
    pleaseConfirmNewPass: '確認用にもう一度入力してください',
    passSettingComplete: 'パスワードの設定が完了しました',
    forgotPassTitle: 'パスワード設定',
    weSendLink: 'パスワード設定用のリンクをお送りします。',
    registerDescription: '新フローティング会員の説明',
    remaingPoint: 'あなたのポイント残数',
    searchCriteria: '検索条件',
    searchResult: '検索結果',
    reordering: '並び替え',
    facility: '施設',
    noVacancy: '満室',
    freeParking: '駐車場無料',
    noSmoking: '禁煙',
    smoking: '喫煙',
    belovedDog: '愛犬',
    noSmokingForDog: '愛犬禁煙',
    smokingForPet: '愛犬喫煙',
    aikenGardenSmall: '愛犬ガーデンスモール',
    myDogGardenLarge: '愛犬ガーデンラージ',
    nGauge: 'Nゲージ',
    vacant: '空室',
    fewRemaining: '残りわずか',
    noCheckin: 'チェックイン不可',
    year: '年',
    month: '月',
    nRoom: '部屋数',
    child: '子供',
    sleepTogether: '添い寝あり',
    noSleepTogether: '添い寝なし',
    nGuest: '宿泊人数',
    total: '合計',
    room: '部屋',
    night: '泊',
    totalPointFor: 'の合計ポイント',
    firstName: '名',
    yen: '円',
    membersName: '会員様お名前',
    contactTitle: '会員様ご連絡先情報',
    address: '住所',
    profession: '職業',
    age: '年齢',
    sex: '性別',
    email: 'メール',
    passportNumber: 'パスポート番号',
    countryOfCitizenship: '国籍',
    loginEmailAddress: 'ログインメールアドレス',
    sendingAddress: '送付先住所',
    loginPassword: 'ログインパスワード',
    dateOfBirth: '生年月日',
    phoneNumber: '電話番号',
    phone: '電話',
    welcome: 'いらっしゃいませ、',
    cardName: 'カード名義',
    cardNumber: 'カード番号',
    securityCode: 'セキュリティコード',
    ticketNumber: 'チケットNo.',
    authenticationNumber: '認証用番号',
    faq: 'よくあるご質問',
    contactUs: 'お問い合わせ',
    expirationDate: 'ご利用期限',
    dateAndTime: '日時',
    contactAddress: '連絡先',
    telephone: '電話',
    mobile: '携帯電話番号',
    creditCard: 'クレジットカード',
    introduction: 'ご紹介',
    receipt: '領収書',
    registered: '登録済み',
    memberName: '会員名',
    postalCode: '郵便番号',
    province: '都道府県',
    district: '市区郡',
    street: '町名・番地',
    buildingName: 'ビル名・マンション名等',
    yourName: 'お名前',
    name: '名前',
    surname: '姓',
    katakana: 'フリガナ',
    hiragana: 'よみがな',
    sei: 'セイ',
    mei: 'メイ',
    day: '日',
    relationship: '続柄',
    gender: '性別',
    male: '男性',
    any: '任意',
    female: '女性',
    sharingPoint: 'シェアリングポイント',
    flexPoint: 'フレックスポイント',
    remainingPoints: '残りのポイント',
    numberOfPointsRequired: '必要ポイント数',
    availablePoints: '利用可能ポイント',
    beRedeemed: 'で交換可能',
    currentPoints: '現在のポイント',
    addressee: 'お届け先',
    numberOfPointsBeforeExpiration: '期限内ポイント数',
    endDate: '終了期限',
    nextDeadLine: '次回期限',
    startDeadLine: '期限開始',
    viewDetails: '詳細を見る',
    myWeek: 'マイウィーク',
    contractYear: 'ご契約年度',
    status: 'ステータス',
    rightsFacilities: '権利施設',
    primeWeek: 'プライムウィーク',
    yearOfStartOfrightUse: '権利利用開始年',
    weeklySpecialNumber: '週番号',
    roomType: '部屋タイプ',
    vacationMaster: 'バケーションマスター',
    dateOfCommencementOfRightLodging: '権利宿泊開始日',
    endDateOfRightLodging: '権利宿泊終了日',
    contract: '契約',
    contractNumber: '契約番号',
    contractYears: '契約年数',
    contractExpirationDate: '契約満了日',
    reservationId: '予約ID',
    checkinDate: 'チェックイン日',
    finalDate: '最終日',
    pleaseSelectTheDateYouWantToCancel: 'キャンセルする日程を選択してください',
    reservationTime: '予約日時',
    cancellationPolicy: 'キャンセルポリシー',
    newBookingWarning: '新規予約の追加に関するご注意',
    cancelPointName: 'キャンセル <ポイント名>',
    totalCancellationPointName: '合計キャンセル<ポイント名>',
    restitutionPointName: '返還<ポイント名>',
    pleaseEnterYourLoginPassword: 'ログインパスワードを入力してください',
    ownershipVacationMaster: '所有バケーションマスター',
    pastVacationMaster: '過去のバケーションマスター',
    pointNameHistory: '<ポイント名>履歴',
    endOfTerm: '期限終了',
    remainingPointName: '残<ポイント名>',
    expiryDate: '所有<ポイント名>期限',
    numberOfPointsRemainingForThisYear: '今年度残ポイント数',
    carryOverCharge: '繰越料金 ポイント単価',
    numberOfAdditionalPoints: '追加ポイント数',
    current: '現在',
    endOfExpireDate: 'ポイント期限終了',
    additionalPointsPerUnit: '追加ポイント単価',
    additionalChargesWithTax: '追加料金(税込)',
    schellingPoint: 'シェリングポイント',
    do: 'します',
    fee: '料金は',
    beCharged: '円請求されます',
    xxThePointName: '<ポイント名>をXXする',
    rightWeek: '権利週',
    state: '状態',
    firstPart: '前半',
    secondPart: '後半',
    deadlineForAcc: '受付期限',
    dateOfDivision: '分割日',
    numberOfNight: '宿泊数',
    depositMWYear: 'デポジットMW年度',
    formerFacilities: '元施設',
    formerRank: '元ランク',
    formerWeekNumber: '元週番号',
    scope: '範囲',
    selectDate: '日程を選択',
    selectFac: '施設を選択',
    searchByTag: 'タグで検索',
    numberPeople: 'ご利用人数',
    petAllowed: 'ペット可',
    startDay: '開始日',
    missingPoint: '不足ポイント数',
    checkOut: 'チェックアウト',
    capacity: '定員',
    totalPerMinute: '分の合計ポイント',
    currentPoint: '現在お手持ちのポイント',
    afterBook: '予約後のポイント',
    dayBeforeEvent: '○日前',
    otherExpense: 'その他費用について',
    allTaxInclude: '※表示の価格は全て税込です',
    numberOfPointsUsed: '使用ポイント数',
    cleaningFee: '清掃料',
    totalUsageFee: '利用料合計',
    rentalListingTotal: '貸出品合計',
    totalCharge: '合計料金',
    bookExchange: '交換予約',
    requester: '交換元',
    afterReplacment: '交換後',
    fiscalYear: 'MW年度',
    rank: 'ランク',
    exchangeFee: 'MW交換手数料',
    exchangedReservations: '予約交換しました',
    memberRegistration: 'Vacation Style会員登録',
    fullName: '氏名',
    nameKana: '氏名（カナ）',
    cellPhoneNumber: '携帯電話番号',
    corporationName: '法人名',
    corporateName: '法人名 フリガナ',
    corporateNameNo: '国税庁法人番号',
    nameOfPersonInCharge: 'ご担当者名',
    nameDepartmentOrPosition: 'ご担当者部署・役職名',
    awardCode: '特典コード',
    loginID: 'ログインID',
    paymentMethod: '支払方法',
    membershipFee: 'メンバーシップフィー',
    benefitsApplied: '特典適用',
    contractAutomaticRenewalSetting: '契約自動更新設定',
    contractDuration: '契約継続年数',
    pleaseRead: '必ずお読み下さい',
    faqPage: 'よくあるご質問',
    contractStartDate: '契約開始日',
    peopleUse: '人利用',
    totalOf: 'の合計',
    noContact: '連絡なし',
    onDay: '当日',
    dayBefore: '日前',
    pets: 'ペット',
    people: '人',
    necessary: 'あり',
    unnecessary: 'なし',
    points: 'ポイント',
    logout: 'ログアウト',
    cancelVacancyNotification: '空室通知を解除します。よろしいですか？',
    parkingInformationNotAvailableMessage: 'ご指定の施設では駐車場はご予約いただけません',
    question: '質問{number}',
    answer: '回答'
  },
  filter: {
    inOrderOfHighestPrice: '価格が高い順',
    inOrderOfLowestPrice: '料金の安い順'
  },
  statement: {
    companyAddress: '軽井沢',
    target: '対象',
    totalUse: '利用合計',
    proviso: '但',
    companyNameText: '東急バケーションズ軽井沢',
    addressText: '〒389-0102 長野県北佐久郡軽井沢町軽井沢1016-652',
    consumptionTax: '内消費税',
    inlandRevenueTax: '内入湯税',
    accommodationTax: '内宿泊税',
    hotSpringTax: '入湯税',
    occupancyTax: '宿泊税',
    mainPrice: '本体代金',
    roomCharge: 'ご宿泊料',
    amountOfReceipt: '領収書金額',
    noteReceipt: '上記の金額正に受領いたしました'
  },
  vacancy: {
    vacancyNotificationList: '空室通知登録リスト',
    vehicleHeightLimit: '車高制限',
    parkingLotRegistrationList: '駐車場登録リスト',
    bookWithMastersOption: 'マスターズオプションで予約',
    bookWithSharingPoint: 'シェアリングポイントで予約',
    bookWithFlexPoint: 'フレックスポイントで予約',
    dayPlanOrPreferentialRate: 'デイプラン・優待料金',
    kyoto28Weeks: '京都28週',
    karuizawa50Weeks: '軽井沢50週',
    bookAtMyWeek: 'マイウィークで予約',
    exchangeMyWeek: 'マイウィークの交換',
    bookWith: 'で予約',
    startDate: '開始日',
    endDate: '終了日'
  },
  buttons: {
    officialWebsite: '公式サイト',
    cancel: 'キャンセル',
    ok: 'オーケー',
    registerEmail: 'メールアドレス登録',
    sending: '送信',
    register: '登録',
    setUp: '設定する',
    toLoginScreen: 'ログイン画面へ',
    showUserCertificate: '利用証を表示する',
    downloadDocuments: '資料ダウンロード',
    enterYourInfo: '情報を入力する',
    issueAReceipt: '領収書を発行する',
    cancelNotification: '通知解除',
    alreadyNotified: '通知済み',
    display: '表示',
    change: '変更する',
    editDeputyMemberDetails: '副会員詳細変更',
    editFamilyMemberDetails: 'ファミリー会員詳細変更',
    addFamilyMembers: 'ファミリー会員を追加',
    save: '保存',
    findAnAddress: '住所を検索',
    changeToFamilyMemberAndSave: 'ファミリー会員に変更して保存',
    deleteThisMember: 'この会員を削除',
    selectATicket: 'チケットを選択する',
    return: '戻る',
    enterNumberOfTickets: '枚数を入力',
    order: '注文する',
    confirmNumberOfTickets: '枚数を確定',
    partialCancellation: '部分キャンセル',
    reservationDetails: '予約詳細',
    addPointName: '<ポイント名>追加',
    carryOverBtn: '<ポイント名>繰越',
    addBtn: '<ポイント名>を追加する',
    backToTop: 'トップへ戻る',
    showReceipts: '領収書を表示',
    reserveDiv: '予約分割',
    deposit: 'デポジット',
    divideWholeReservation: '全体を予約分割',
    divideFirstHalfReservation: '前半を予約分割',
    divideSecondHalfReservation: '後半を予約分割',
    split: '分割する',
    returnToTop: 'TOPページへ戻る',
    totalDeposit: '全体デポジット',
    firstDeposit: '前半をデポジット',
    secondDeposit: '後半をデポジット',
    makeDeposit: 'デポジットする',
    completeSelection: '選択を完了する',
    deselect: '選択を解除する',
    checkAll: '全てを選択する',
    decision: '決定',
    front: '<ポイント名>前倒',
    whole: '全体交換の空室検索',
    firstPart: '前半交換の空室検索',
    latePart: '後半交換の空室検索',
    reserveAndExchange: '予約交換する',
    viewTheListOfReceipts: '領収書一覧を表示',
    clickHereForDetails: '詳しくはこちら',
    viewMore: 'さらに見る',
    toTokyuWebsite: '東急バケーションズ公式ホームページへ',
    viewUsageGuide: 'ご利用ガイドを見る',
    vacationLifeBackNumber: 'バケーションライフ バックナンバー',
    viewQRUsage: 'QR・利用証票を表示します',
    search: '検索',
    selectDate: '日程を選択',
    selectFacility: '施設を選択',
    selectRoomType: '部屋タイプを選択',
    selectTheRoomTypeAbove: '上記の部屋タイプを選択',
    numberOfPersons: 'ご利用人数',
    confirm: '決定',
    ticketUserPage: 'チケット利用者様ページ',
    plusPoints: 'ポイント追加・繰越申請',
    usePoint: 'ポイント前倒し利用申請',
    next: '次へ',
    loginSecond: 'ログインする',
    appAddPoint: 'ポイント追加申請',
    pointForApp: 'ポイント繰越申請',
    checkUsage: 'ご利用内容を確認してください',
    send: '送信する',
    changeTheCondition: '条件を変更する',
    inOrderOfLeast: '料金の安い順',
    openFacilityInformation: '施設インフォメーションを開く',
    goToReservation: '予約へ進む',
    addWaitList: '空室通知へ進む',
    notify: '通知を希望する',
    stop: 'やめる',
    checkIn: 'チェックイン',
    home: 'ホーム',
    agreeAndEnterMyDetails: '上記に同意して情報入力へ',
    checkPointsHistory: 'ポイント履歴を確認する',
    finalizeBooking: '予約内容の最終確認へ',
    confirmBooking: '予約を確定する',
    backToMainPage: 'メインページに戻る',
    release: '解除する'
  },
  daysOfWeek: {
    mon: '月',
    tue: '火',
    wed: '水',
    thu: '木',
    fri: '金',
    sat: '土',
    sun: '日'
  },
  unit: {
    point: 'ポイント',
    points: 'ポイント',
    ticket: '枚',
    tickets: '枚'
  },
  text: {
    noPreCheckIn: '東急バケーションズ施設以外への宿泊では事前情報入力はご利用頂けません。',
    wishChangeOptions: 'ご予約内容を変更・キャンセルする場合は以下より選択して下さい',
    openFacInfo: '施設インフォメーションを開く',
    completeUsageConfirm: '利用確認を完了する',
    cancelChargePt: 'キャンセル料/ポイント',
    pressThisButton: 'このボタンを押して下さい',
    ifNoChanges: 'ご予約内容に変更がなければ',
    specifiedCouldNotBeFound: 'ご指定のご予約が見つかりませんでした',
    notHavePermissionViewReservation: 'この予約の閲覧権限がありません',
    pleaseSelectFromTheFollowingOptions: 'ご予約内容を変更・キャンセルする場合は 以下より選択してください',
    completeTheUsageVerification: '利用確認を完了する',
    noChangesPleaseClickThisButton: 'ご予約内容に変更がなければこのボタンを押してください',
    dueToChangesInGuestInfo: '宿泊者情報の変更による',
    thisReservationCannotBeChanged: 'この予約は変更することができません',
    cancelSomeDates: '一部日程のキャンセル',
    enterYourPreCheckInInfo: '宿泊者情報を入力する',
    preCheckInInfoLater: '宿泊者情報を後で入力する',
    facInfoPage: '施設インフォメーションページ',
    orSpecificInfo: '施設によって受付時間が設定されている場合や、個別ご案内情報がございますので施設インフォメーションページを必ずご確認下さい。',
    changedMyReservation: '予約を変更しました。',
    uploadYourPassport: 'パスポートをアップロード',
    lodgingRepresentative: '宿泊代表者',
    tokyuTaro: '東急太郎',
    preCheckInfoHasBeenSaved: '事前情報入力を保存しました。',
    preCheckInInfo: '事前情報入力',
    anErrorOccurredWhileLoading: '読み込み中にエラーが発生しました',
    hasBeenReturned: 'が返還されました',
    canceledMyReservation: '予約をキャンセルしました',
    numberOfPointsPossessed: '所持ポイント数',
    numberOfPointsUsed: '利用ポイント数',
    useVacationsPointsForPayment: '支払いに東急バケーションズポイントを利用',
    cancellationCharge: 'キャンセル料',
    reservationDateAndTime: '予約日時',
    cancellationFeeBreakdown: 'キャンセル料金内訳',
    totalCancelationFee: '合計キャンセル料金',
    totalCancellation: '合計キャンセル',
    cardAsCancellationFees: 'キャンセルポリシーに従って以下の料金がキャンセル料として登録のクレジットカードに請求されます',
    pointsWillBeRefunded: 'キャンセルポリシーに従って以下のポイントがキャンセル料として請求されますので、残りのポイントが返還されます',
    returnedToYou: 'が返還されます',
    areYouSureCancelReservation: '本当に予約をキャンセルしますか',
    cancellationOfSomeDate: '一部日程のキャンセル',
    selectCancelFromLastDay: '最終日からキャンセルする日数を選択してください',
    selectCancelStayDates: 'キャンセルされたい宿泊日を選択してください',
    canOnlyCancelLastDay: '※最終日からのキャンセルのみ可能です',
    partialScheduleCancellation: '一部日程キャンセル',
    cancelAllReservation: '予約全日程キャンセル',
    enterPreCheckInInfo: '事前情報入力',
    optionalCleaning: 'オプション清掃',
    haveConfirmationOfUse: '利用確認をしました',
    noteThatReservationsMade: '以降の予約はご利用頂けませんので、ご了承下さい',
    membershipWillBeAutomatically: '末をもって、自動で退会となります',
    annualFeeWillBeCharged: 'に年会費を登録頂いたクレジットカードから請求させて頂きます。その後、1年間同様にご利用頂けます。',
    youCanNotAutoRenew: '以降に予約が存在しますので、契約を自動更新しないを選択できません。該当のご予約をキャンセルいただいた後に変更できます',
    roomType: {
      select: '部屋タイプを選択',
      pleaseSelect: '部屋タイプと希望日を選択してください'
    },
    reservationCalendar: {
      select: '希望日を選ぶ'
    },
    facility: {
      select: '施設を選択',
      pleaseSelect: 'ご希望の施設を選択して下さい'
    },
    vacancyUn: {
      full: 'ご希望の期間は満室となっております。空室が出た際の通知を希望されますか？',
      desc: '※通知の設定はメニューの「キャンセル待ち」からいつでもご確認・変更が可能です'
    },
    selectPlan: {
      pleaseSelect: 'プランをお選びください',
      plan: 'プラン',
      planNotes: 'プランの注意事項',
      question: '質問内容{number}',
      notAnswered: '無回答'
    },
    stage: {
      planSelection: 'プラン選択',
      reservationConfirmation: '予約確認',
      dataEntry: '情報入力',
      decision: '確定',
      memberInformation: '会員情報',
      paymentMethod: 'お支払い方法',
      confirmation: '確認',
      complete: '完了'
    },
    reservationConfirm: {
      please: '予約内容をご確認ください',
      cancellationPolicyDesc: '8日前までのキャンセルは無料です、それ以降 7日前 20% 2日前50% 当日80% 連絡なし 100%のポイントをキャンセル料としてお預かりします。',
      cancellationPolicyWarm: 'ご予約後にキャンセルした場合、キャンセル料が発生する日程となっておりますのでご了承お願いいたします',
      otherExpense: 'こちらの施設は清掃料4000円が必要になります。また、1500x3泊x人数分の利用料が発生します。'
    },
    exchange: {
      select: '交換予約するマイウィーク年度を選択してください',
      selectRange: '交換予約する範囲を選択してください',
      underSelection: '選択中のマイウィーク年度の'
    },
    register: {
      homeAddress: '自宅住所',
      homeContact: '自宅連絡先',
      companyContact: '会社連絡先',
      title: '会員情報の登録',
      fill: 'メールアドレスの認証が完了しました。以下の情報を登録してください。',
      changeEmail: '※E-mailはマイページにてご変更いただけます。',
      passwordHint: '※英語数字を含む8文字以上を入力してください。',
      passwordCon: 'パスワード（確認用）',
      required: 'メールアドレス、パスワードはマイページへのログイン時に必要となります。',
      nameNotChange: '※一度ご登録いただいた氏名（カナ）の変更はできません。',
      birthNotChange: '※一度ご登録いただいた生年月日の変更はできません。',
      yourAddress: 'ご住所',
      companyAddress: ' 法人住所',
      postalCodeExample: '例：1500002（半角）',
      addressSearch: '住所検索',
      provinceExample: '例：東京都',
      districtExample: '例：渋谷区',
      buildingNameExample: '例：渋谷地下鉄ビル3F',
      streetExample: '例：渋谷1-16-14 ',
      optionalFill: 'どちらか1つは入力してください',
      phoneNumberExample: '例：03-3406-0109 （半角）',
      cellPhoneNumberExample: '例：090-1234-5678 （半角）',
      descPrevent: 'お客様情報の重複登録を防ぐため、東急バケーションズ会員番号をお持ちの方はご入力下さい。初めて登録される方は入力不要です。',
      corporationNameExample: '例：株式会社東急シェアリング',
      corporateNameExample: '例：カブシキガイシャ トウキュウシェアリング',
      corporateNameNoExample: '例：1234123412345',
      nameOfPersonInChargeExample: '例：東急　太郎（全角）',
      katakanaExample: '例：トウキュウ　タロウ（全角）',
      departmentExample: '例：営業部',
      positionExample: '例：部長',
      exitDesc: '※既存会員様の場合は、ご入力ください。電話番号',
      registerPayment: 'Vacation Styleメンバーシップフィーのお支払い方法を登録してください。',
      whatIsSecCode: '＊セキュリティコードとは？',
      ifAwardCode: '特典コードをお持ちの方は入力してください。',
      pleaseConfirm: 'ご入力いただいた会員情報をご確認ください。',
      pleaseAnswer: '以下のアンケートにお答えください。',
      howDidHear: '東急バケーションズは何でお知りになりましたか？',
      webSearch: 'インターネット検索',
      officialSNS: '公式SNS',
      accommodationBooking: '宿泊予約サイト',
      tv: 'テレビ',
      magazine: '雑誌',
      introduction: 'ご紹介',
      JALVacations: 'JALバケーションズ',
      answeredOther: 'その他と答えた方は、',
      answeredOtherOptional: '具体的な内容をご入力ください。',
      getStartBill: 'ご利用の開始・請求について',
      confirmDesc: '会員登録完了後、宿泊のご利用を開始いただけます。メンバーシップフィーは翌月に請求いたします。契約は自動更新となります。',
      membershipIncluded: '会員規約が入ります',
      notationBased: '特定商取に基づく表記　掲載',
      agree: '上記の規約に同意します',
      pleaseSure: '※登録ボタンを押すと入会が確定しますので、十分にご確認頂いた上で登録を実施してください。',
      registerComplete: '会員情報の登録が完了しました。',
      completePleaseWait1: '登録頂いた内容の確認には数日お時間をいただきますので、ご利用開始まで暫くお待ち下さい。',
      completePleaseWait2: '確認完了後、ご登録頂いたメールアドレスに通知をお送り致しますので、メールに記載されたマイページよりログインをお願い致します。'
    },
    notRegistered: {
      title: '空室通知登録リストについて',
      text: 'こちらでは過去に登録した空室通知が確認できます。新規で空室通知を登録する場合は、空室検索から登録ください。'
    }
  },
  reservationAndUsageHistory: {
    noExtraChargePoints: '追加料金/ポイントはありません',
    reservationAndUsageHistory: '予約変更・予約履歴',
    reservationDetails: '予約詳細',
    reservationChange: '予約変更',
    confirmation: '確認',
    theLatest: '直近のご予約',
    enterInformationAndConfirmUsage: '情報を入力して利用確認',
    currentRoomReservation: '現在の宿泊予約',
    pastAccommodationReservations: '過去の宿泊予約',
    changeReservationDetails: '予約内容を変更する',
    pleaseSelectTheContentToBeChangedFromTheFollowing: '変更する内容を下記から選択してください',
    extensionOfAccommodationDate: '宿泊日延長',
    moveOfAccommodationDate: 'チェックイン／アウト日の変更',
    changeGuestInformation: '宿泊者情報',
    changeGuestInformationChange: '宿泊者情報変更',
    changePetInfo: 'ペット情報',
    changePetInfoChange: 'ペット情報変更',
    changeRentalListing: '貸出品',
    changeRentalListingChange: '貸出品変更',
    changeParkingLot: '駐車場',
    changeParkingLotChange: '駐車場変更',
    changeTVPPrePayment: '東急バケーションズポイント利用',
    changeTVPPrePaymentChange: '東急バケーションズポイント利用変更',
    return: '戻る',
    accommodationDateExtension: '宿泊日延長',
    accommodationDateExtensionTitle: '宿泊初日以前に日数を追加する場合は新規での予約をお願いします。宿泊日程を減らす場合は予約キャンセルのメニューからお願いします。',
    accommodationDateMove: 'チェックイン／アウト日の変更',
    extendTheDay: '日延長する',
    save: '保存',
    byAddingDays: '日数追加による',
    thereIsAnAdditionalChargePoints: '追加料金/ポイントがあります',
    additionalChargesAdditionalPointsDueToAdditionalDays: '日数追加による追加料金/追加ポイント',
    additionalCharge: '追加料金/追加ポイント',
    chargeChangeDueToMov: '日程変更による宿泊料金・ポイントの増減',
    cancelPenaltyDueToDecreasedDays: '日数短縮によるキャンセル料/ポイント',
    allListedPricesIncludeTax: '※表示の価格は全て税込です',
    pleaseEnter: '入力してください',
    yen: '円',
    loginPasswordForConfirmation: '確認のためログインパスワードを',
    guestInformation: '宿泊者情報',
    pleaseEnterTheChangeInTheDesiredItem: '変更をご希望の項目に入力してください',
    adult: '大人',
    name: '名',
    child: '子供',
    thereIsCoSleeping: '添い寝あり',
    noBedSharing: '添い寝なし',
    aboutCheckIn: 'チェックインについて',
    pleaseCheckInFrom0To0: '〇時～〇時にチェックインをお願いします。',
    aboutCheckInText: '上記時間外になる場合は施設へご連絡下さい。',
    accommodationRepresentative: '宿泊代表者',
    guestName: '宿泊者名',
    mandatory: '必須',
    changeToTheAboveContent: '上記の内容に変更する',
    rentalListingChange: '貸出品変更',
    noRentalListingRequired: '貸出品 不要',
    borrowRentalListing: '貸出品を 借りる',
    lendingPeriod: '貸出期間',
    numberOfRentalListingGroups: '貸出品グループ数',
    rentalExhibitionGroup: '貸出品グループ',
    usedForAllDates: '全日程で 利用',
    useOnlyForSpecificDates: '特定の日程 のみ利用',
    rentalListing: '貸出品',
    totalRentalAmount: '貸出品合計金額',
    totalFee: '合計金額',
    numberOfCars: '車台数',
    car: '車',
    number: 'ナンバー',
    colour: '色',
    startDateOfUse: '利用開始日',
    endOfUseDate: '利用終了日',
    from: 'から',
    parkingLotFormat: '駐車場形式',
    only1Parking: '駐車場の複数選択はできません。キャンセル待ち登録も含め選択はお一つとなります。',
    mechanical: '機械式',
    flatPlacement: '平置き',
    vehicleHeightLimit: '車高制限',
    noVehicleHeightLimit: '車高制限なし',
    full: '満車',
    registerWaitingForCancellation: 'キャンセル待ち登録をする',
    parkingLot: '駐車場を',
    use: '利用する',
    doNotUse: '利用しない',
    flat: '平置き',
    seeMore: 'さらに見る',
    usageHistory: '利用履歴',
    dateAndTime: '日時',
    username: 'ユーザー名',
    operation: '操作',
    guest: '宿泊者',
    accommodationSchedule: '宿泊日程',
    lendingSchedule: '貸出日程',
    noParkingRequired: '駐車場予約',
    overMaxOccupancy: '選択された人数が部屋の定員を超えることはできません',
    enterAccommodationDetails: 'ご宿泊詳細をご入力下さい'
  },

  memberInfo: {
    publish: '発行する',
    pointName: '<ポイント名>',
    description: 'メールアドレスを変更してもログイン用のメールアドレス（ログインID）は変更されません。そちらも合わせてご変更される場合はコールセンターまでご連絡下さい',
    processing: '処理',
    changeCreditCardInfo: 'クレジットカード情報を変更する',
    haveChangedCreditCardInfo: 'クレジットカード情報を変更しました',
    registerCreditCardInfo: 'クレジットカード情報を登録する',
    vacationsPoints: '東急バケーションズポイント',
    vacationsPointsHistory: '東急バケーションズポイント履歴',
    reservationUse: '予約利用',
    changeContact: '連絡先変更',
    changeMFAPolicy: 'ログイン時認証レベル変更',
    changeContactInformation: '連絡先を変更する',
    membershipType: '会員種別',
    changeMembersInfo: 'ファミリー会員一覧',
    aboutPayment: 'お支払いについて',
    confirmOrChangePaymentMethod: '支払い方法の確認・変更',
    changeRegisteredCreditCard: '登録済みのクレジットカードを変更する',
    introduction:
      'ご友人・知人をご紹介いただき、ご契約をいただけますと特典を差し上げます',
    enterInfoFriendsOrCcquaintances: 'ご友人・知人の情報を入力する',
    currentPaymentMethod: '現在の支払い方法',
    payAtTheFacility: '施設で支払い',
    accommodationFee: '宿泊料',
    facilityFee: '利用料',
    setUpFee: 'セットアップ料',
    optionalCleaningFee: 'オプション清掃料',
    inlandRevenueAndAccommodationTax: '入湯税・宿泊税・温泉維持費',
    paidService: '有料サービス',
    deputyMember: '副会員',
    familyMember: 'ファミリー会員',
    useSameAddressAsMember: '正会員と同じ住所を使用',
    introductionTextList: {
      line1: '東急バケーションズにご興味をお持ちのご友人・知人の方がいらっしゃいましたら、ぜひご紹介ください。',
      line2: 'ご紹介を受けた方がご成約となりましたら、御礼として会員様とご紹介を受けたお客様双方に特典をご用意しております。',
      line3: '下記にてご紹介を受けたお客様の電話番号に、当社営業担当から連絡を行いますので、事前にご案内をお願い申し上げます'
    },
    enterFriendInfo: 'ご友人・知人の情報をご記入ください',
    thankYouForIntro: 'ご紹介いただきまして誠にありがとうございます。',
    thanhYouForIntroDescription: 'ご紹介を受けたお客様がご成約となりましたら、御礼と特典のご案内で営業担当より連絡させていただきますので、引き続きご愛顧のほど宜しくお願い致します。'
  },
  // ================================ Pages ================================
  loginPage: {
    saveYourLoginID: 'ログインIDを保存する',
    forgotYourPassword: 'パスワードをお忘れの方',
    forgotYourId: 'IDを忘れた方はコールセンター(0120-618-109)にご連絡ください',
    membershipAgree: '会員規約'
  },
  homePage: {
    pleaseBeSureToReadIt: '必ずお読みください',
    latestReservation: '直近のご予約',
    downloadDocuments: '資料ダウンロード',
    FAQ: 'よくあるご質問',
    membersOnlyDial: '会員専用ダイアル',
    searchConditions: '検索条件',
    freeParking: '駐車場無料',
    noSmoking: '禁煙',
    smoking: '喫煙',
    urgentInformation: '緊急のご案内',
    viewUsageCertificate: '利用証票を表示',
    vacationLifeBackNumber: 'バケーションライフ バックナンバー',
    callCenterDial: 'コールセンターダイヤル',
    availableServiceNotice: {
      line1: 'まもなくご利用開始となります',
      line2: 'ご利用開始前の情報入力をお願いいたします'
    },
    notification: {
      expirationDateSharingPoints:
        'ご契約のシェアリングポイントの利用期限が近くなっております。',
      exchangingMyWeekDeadline:
        'デポジットしたマイウィークの交換期限が近くなっております。',
      depositMyWeekDeadline:
        'ご契約のマイウィークのデポジット期限が近くなっております。'
    }
  },
  newsPage: {
    memberEventInfo: '会員イベント情報',
    urgentInformation: '緊急のご案内',
    contractList: 'ご契約一覧'
  },
  contract: {
    title: {
      title1: 'プライムウィーク',
      title2: 'フレックスポイント',
      title3: '新固定商品',
      title4: 'マイウィーク'
    },
    contractYear: {
      contractYear1: 'ご契約年度',
      contractYear2: '期限内ポイント数',
      week: '週'
    },
    year: {
      year1: '年度',
      point: 'ポイント'
    },
    rightBasis: {
      rightBasis1: '権利施設',
      rightBasis2: '終了期限'
    },
    btn: '詳細を見る',
    to: 'まで',
    deadline: '次回期限',
    deadlineStart: '期限開始',
    day: '宿泊日',
    form: '開始',
    end: '終了'
  },
  contractDetail: {
    membershipNumber: '会員番号',
    contract: '契約',
    pointsRemaining: 'ポイント残数',
    expirationDatePoint: 'ポイント有効期限',
    pointsLeft: '翌年度以降ポイント残数'
  },
  contractReq: {
    historyStatus: '申請履歴状況',
    forwarding: 'ポイント繰越',
    addPoint: 'ポイント追加'
  },
  booking: {
    hasNoPet: '同伴ペットなし',
    title: '利用履歴',
    pointsBalance: 'ポイント残高',
    useAtCheckOut: '精算時にご利用下さい。',
    dateAndTime: '日時',
    username: 'ユーザー名',
    operate: '操作',
    allFacilities: '全ての宿泊施設',
    facilityInformation: '施設インフォメーション',
    fullyBooked: 'ご希望の期間は満室となっております。',
    willYouNotifyWhenAvailable: '空室が出た際の通知を希望されますか？',
    setUpNotification: '通知を設定する',
    noteRoomAvailability: '※空室通知は予約を確定させるものではございません。',
    forMoreInfo: '詳細については',
    faqPage: 'よくあるご質問ページ',
    findOutMore: 'をご確認下さい。',
    youHaveSetUpNotification: '通知を設定しました',
    viewVacancyNoticeList: '空室通知リストを見る',
    plusBedShareMaxOccupancy: '+ 添寝乳幼児{0}名まで',
    includeSleeping: '(添寝乳幼児含む)',
    totalAccommodationOneNight: '泊分の合計宿泊料',
    cancellationUpToSecondDayFreeOfCharge: '日目までのキャンセルは無料です、それ以降',
    cancellationFeeWillBeChargedAsFollows: 'をキャンセル料としてお預かりします。',
    noCancellationFee: 'キャンセル料は無料です。',
    needToPay: '円が必要になります。',
    noOtherCosts: 'その他費用はございません。',
    pointsRequired: '必要なポイント数',
    selectYourDates: '日程を選択してください',
    accompanyingPets: '同伴ペット',
    petsNumber: 'ペット頭数',
    breeds: '犬種',
    catBreeds: '猫種',
    petOwnerName: '飼い主様名',
    petWeight: '体重',
    petInfo: 'ペット',
    allPetsAreVaccinated: '全てのペットはワクチン接種済みです',
    accompaniedBy: '同伴あり',
    unaccompaniedBy: '同伴なし',
    finalConfirmation: '予約内容の最終確認をお願いします',
    breakdown: '<内訳>',
    sameRoomOnSameBookingBeforeOrAfterDay: '前後日に同じ予約があった場合、同じ部屋番号を希望する',
    sameRoomAvailableText: '同じ部屋番号でご利用可能な場合、上記清掃料が一部免除される可能性がございます。宿泊時にご確認下さい。',
    issuanceCertificateUse: '利用証の発行',
    sendingUserIdCards: '利用証送付',
    howToPayTotalFee: '合計料金のお支払い方法',
    onSite: '現地で',
    payment: '支払う',
    allOnRegisterCard: '全て登録カードで',
    ifHaveSpecRequest: '特別なご要望がありましたら',
    contactUsAt: 'へご連絡ください',
    callCenter: 'コールセンター',
    usageFees: 'バケーションスタイル宿泊料',
    usedPoints: '利用ポイント数',
    thanksForBooking: 'ご予約ありがとうございます',
    bookingConfirmed: '以下の予約を確定いたしました。',
    confirmationSentToEmail: '確認用のメールを{email}に送信しました。'
  },
  typography: {
    weSendLinkForgot: 'メールアドレスにパスワード設定用のリンクをお送りしましたので、そちらからお手続きください',
    registerSubTitle: 'テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト'
  },

  registerPage: {
    note1: '特定商取引法に基づく表記',
    note2: '特定商取　掲載予定 掲載内容ご指示お願いします。',
    confirmed: '確認しました',
    registerWithEmail: 'メールアドレスで登録',
    registerDesContent: '入力されたメールアドレスが最初の「ログインID」になります。',
    memberType: '会員登録',
    individual: '個人',
    corporation: '法人',
    email: 'メールアドレス',
    emailConfirmed: 'メールアドレス（確認用）',
    required: '必須',
    policy: '個人情報保護方針',
    agreePolicy: '上記の規約に同意します',
    submit: '登録',
    submitDescription: '※会員登録のメールが届かない場合はドメイン <span class="no-break">「@tokyu-sharing.co.jp」</span>が受信出来るように設定の変更をお願い致します。 <br>※ドメイン指定の解除方法については各携帯会社のサイト等をご確認ください。'
  },
  giftTicket: {
    itWillBeSent: '1〜2週間後に送付します。',
    alreadyApplied: 'お申し込み済',
    ticketIssuerConfirmed: 'チケット発行元確認済',
    selectThePointsToUse: '利用するポイントを選択してください',
    enterNumOfTicket: {
      line1: '枚数を',
      line2: '入力してください'
    },
    applicationDetails: 'お申し込み内容',
    numberOfPointsAfterExchange: '交換後のポイント数',
    orderNoticeSending: '会員登録された以下の住所へ送付いたします。',
    orderCompleteTitle: 'ご注文が完了しました。',
    orderCompleteDetail: '頂いたご注文が正常に登録されました。発送まで今しばらくお待ち下さい。'
  },
  rules: {
    emailIsRequired: 'メールアドレスは必須です。',
    emailMustBeValid: 'メールアドレスが無効な形式です。',
    emailNotMatch: 'メールが一致しません',
    passwordIsRequired: 'パスワードが必要です。',
    passwordRules:
      'パスワードは８文字以上かつ、小文字・大文字・数字・記号のうち少なくとも２種類を含む必要があります。',
    passwordNotMatch: 'パスワードが一致しない',
    invalid: '入力内容に誤りがあります。',
    isRequired: '必須項目です。',
    required: '必須',
    childrenAge: '18歳未満は登録できません',
    hyphenInHalfWidthNumbersNotice: '半角数字でハイフンを入力してください',
    insufficientPoints: 'ポイントが不足しています',
    pointsNeededToRedeem: 'ポイントの交換は',
    pointsTo: 'ポイントから',
    willBe: 'となります',
    invalidAge: '0以上の数字を入力して下さい',
    cardHolderAlphabet: '半角英字でご入力下さい'
  },
  downloadDocuments: {
    downloadDocuments: '資料ダウンロード',
    download: 'ダウンロード',
    clickHereForLastYear: '昨年度分の利用ガイドはこちら',
    vacationLifeBackNumber: 'バケーションライフバックナンバー'
  },
  // ------------------------------- :)) -------------------------------
  toast: {
    checkEmail: 'ログイン用のURLをメールでお送りいたしましたのでご確認下さい。',
    welcome: 'ようこそ'
  }
}
