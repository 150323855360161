import store from '@/store'

export default async function ensureAuthenticated (to, from, next) {
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.state.user.token) {
      // not logged in
      return next('/login')
    }
    if (store.getters.needsTokenRefresh) {
      // check if token refresh is needed, if it is refresh it
      try {
        await store.dispatch('refreshAccessToken')
      } catch (e) {
        console.log(e)
        return next('/login')
      }
    }

    // check card registration unless noCard=true route
    if (!to.matched.some(record => record.meta.noCard) && !store.getters.hasValidCard) {
      const hasCard = await store.dispatch('checkSavedCard')
      if (!hasCard) {
        return next({
          name: 'home-member-info-new-credit-card',
          query: {
            from: to.fullPath
          }
        })
      }
    }
    // now check if all the payment methods are specified (only company user)
    if (!to.matched.some(record => record.meta.noPaymentMethod) &&
      store.getters.isCompanyUser &&
      !store.getters.arePaymentMethodsSet
    ) {
      await store.dispatch('getPrefPaymentMethod')
      if (!store.getters.arePaymentMethodsSet) {
        // force user to set it
        return next({
          name: 'home-member-info-payment-method',
          query: { 'please-set': '1' }
        })
      }
    }
    return next()
  } else {
    return next()
  }
}

export const requireAuthMeta = () => ({
  auth: true
})
