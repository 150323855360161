import actions from './actions'

const ticket = {
  state: {},
  mutations: {},
  getters: {},
  actions
}

export default ticket
