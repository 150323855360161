<template>
  <header
    :class="isScrollHeader || !this.isHomePage ? '--scroll' : ''"
    v-scroll="handleScroll"
  >
    <div class="t-toolbar" :class="{ '--disable': isShowMenu }">
      <div class="t-toolbar__left">
        <router-link to="/home" class="t-toolbar__logo">
          <Logo :width="170" :height="41" />
          <span class="grey--text text--darken-2 t-toolbar__logo__postfix">
            {{ $t("title.membersOnlySite.header") }}
          </span>
        </router-link>
      </div>
      <v-btn
        elevation="0"
        v-if="!hideHamburgerButton"
        color="var(--color__primary)"
        @click.stop="isShowMenu = !isShowMenu"
      >
        <v-icon large color="var(--color__white)">mdi-menu</v-icon>
      </v-btn>
    </div>

    <nav v-show="isShowMenu" class="move-in-left" @scroll="handleScrollNav">
      <div class="text-right text-md-left mb-0 mb-md-15 close-nav">
        <button
          @click.stop="isShowMenu = false"
          :class="{ '--scroll': isScrollNav }"
        >
          <div class="icon-close" />
        </button>
      </div>
      <ListMenu isHeader @close="isShowMenu = false" />
    </nav>
  </header>
</template>

<script>
import ListMenu from './ListMenu'
import Logo from '@/components/icons/Logo'

export default {
  name: 'AppLayoutHeader',
  components: {
    ListMenu,
    Logo
  },
  props: {
    hideHamburgerButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleScrollNav (e) {
      if (typeof window === 'undefined') return
      const top = e.target.scrollTop || 0
      this.isScrollNav = top > 20
    },
    handleScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || 0
      this.isScrollHeader = top > 100
    }
  },
  computed: {
    isHomePage () {
      return this.$route.name === 'home'
    }
  },
  data () {
    return {
      isShowMenu: false,
      isScrollNav: false,
      isScrollHeader: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/styles/modules/variables-scss";

@media (min-width: $breakpoint__md) {
  .t-toolbar {
    &__logo {
      svg {
        width: 170px !important;
        margin-right: 10px !important;
      }

      &__postfix {
        font-size: 16px;
        margin-top: 20px !important;
      }
    }
  }
}

@media (max-width: 376px) {
  .t-toolbar {
    &__logo {
      &__postfix {
        display: none;
      }
    }
  }
}

header {
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;

  &:not(.--scroll) {
    .t-toolbar {
      background: transparent;

      &__left {
        visibility: hidden;
      }
    }
  }
  .t-toolbar {
    background-color: var(--color__white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left {
      padding-left: 20px;
      width: calc(100% - var(--height__header));
      &.--border {
        border-bottom: solid 1px var(--color__dark-smoke);
        border-left: solid 1px var(--color__dark-smoke);
      }
    }
    &__logo {
      display: inline-flex;

      svg {
        width: 145px;
        margin-top: 8px;
        margin-right: 8px;
      }

      &__postfix {
        margin-top: 22px;
      }
    }
    .v-btn {
      width: 70px;
      height: 70px;
      border-radius: 0 !important;
    }
  }
  nav {
    position: fixed;
    overflow-y: auto;
    overscroll-behavior: contain;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: var(--color__dark-blue--blur);
    .close-nav {
      position: sticky;
      top: 0;
      z-index: 3;
      padding: 10px;
      button {
        padding: 15px;
        width: 60px;
        height: 60px;
        &.--scroll {
          background-color: var(--color__primary);
        }
      }
    }
  }
}
</style>
