import i18n from '@/plugins/i18n'
import { checkEmail } from '@/utils/validate'

export function katakana (value) {
  // eslint-disable-next-line no-irregular-whitespace
  return !value || /^[ァ-ヶー　 ]+$/.test(value) || '全角カタカナでご入力下さい'
}

export function required (value) {
  return !!value || value === 0 || i18n.t('rules.isRequired')
}

export function email (value) {
  return !value || checkEmail(value) || i18n.t('rules.emailMustBeValid')
}

export function maxInt (max, { message } = {}) {
  return value => {
    if (value === null || value === undefined || value === '') return true

    if (typeof value === 'string') value = Number(value)

    return value <= max || message || `${max}以下の値をご入力下さい`
  }
}

export function minInt (min) {
  return value => {
    if (value === null || value === undefined || value === '') return true

    if (typeof value === 'string') value = Number(value)

    if (!Number.isInteger(value)) {
      return '小数点以下は切り捨てでご入力ください'
    }

    return value >= min || `${min}以上の値をご入力下さい`
  }
}

export const onInputPreventNegative = "validity.valid||(value='');"
