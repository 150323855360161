import gql from 'graphql-tag'

export const GET_MY_SAVED_CARD = gql`
  query {
    mySavedCard {
      id
      cardNo
      expire
      holderName
    }
  }
`

export const GET_CC_STATUS = gql`
query {
  checkCCStatus {
    available
    gmoShopId
    maintenanceFrom
    maintenanceTo
    maintenanceMessage
  }
}
`

export const SAVE_CARD = gql`
  mutation ($token: String!) {
    clientSaveCard (token: $token) {
      id
      cardNo
      expire
    }
  }
`

export const GET_PREF_PAYMENT_METHOD = gql`
  query {
    myClientInfo {
      accommodationFeePayment
      facilityFeePayment
      setupFeePayment
      optionalCleaningPayment
      taxPayment
      otherServicePayment
    }
  }
`

export const UPDATE_PREF_PAYMENT_METHOD = gql`
  mutation (
    $accommodationFeePayment: FeePaymentType
    $facilityFeePayment: FeePaymentType
    $setupFeePayment: FeePaymentType
    $optionalCleaningPayment: FeePaymentType
    $taxPayment: FeePaymentType
    $otherServicePayment: FeePaymentType
  ) {
    updateMyClientInfo (
      accommodationFeePayment: $accommodationFeePayment
      facilityFeePayment: $facilityFeePayment
      setupFeePayment: $setupFeePayment
      optionalCleaningPayment : $optionalCleaningPayment
      taxPayment :$taxPayment
      otherServicePayment: $otherServicePayment
    ) {
      id
    }
  }
`

export const UPDATE_MFA_POLICY = gql`
mutation (
  $mfaPolicy: ClientMFAPolicy!
) {
  updateMyClientInfo (
    mfaPolicy: $mfaPolicy
  ) {
    id
  }
}
`
