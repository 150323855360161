<template>
  <div>
    <v-list :class="isHeader ? '--header' : '--footer'">
      <v-row>
        <v-col
          cols="12"
          :md="isHeader ? '6' : '12'"
          :lg="isHeader ? '6' : '10'"
          class="pa-0 col-flex"
          :class="{ 'pr-md-15': isHeader }"
        >
          <div
            v-for="item in listMainMenu"
            :key="item.title"
            @click="handleLink(item)"
            class="mb-4"
          >
            <v-list-group
              :prepend-icon="isHeader ? item.icon : ''"
              :append-icon="
                item.single ? 'mdi-chevron-right' : 'mdi-chevron-down'
              "
              :disabled="item.single"
              :class="{ 'no-translate': item.single }"
              :value="$vuetify.breakpoint.mdAndUp && !isHeader"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-html="item.title" class="text-heading" />
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="child in item.items"
                :key="child.title"
                @click="$router.push(child.link, () => {})"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title" />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-col>

        <v-col
          cols="12"
          :md="isHeader ? '6' : '12'"
          :lg="isHeader ? '6' : '2'"
          class="pa-0 mt-5 mt-md-0"
          :class="{ 'pl-md-15': isHeader }"
        >
          <v-list-item
            v-for="item in listSubMenu"
            :key="item.title"
            @click="handleLink(item)"
          >
            <v-list-item-content class="--sub">
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'ListMenu',
  props: {
    isHeader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleLink (item) {
      if (item.single) {
        this.$router.push(item.link).catch(() => {})
        this.$emit('close')
      }
    }
  },
  data () {
    return {
      listMainMenu: [
        {
          // icon: 7,
          title: this.$t('menu.main.vacationMemberPage'),
          single: true,
          link: '/home',
          items: []
        },
        {
          icon: 'mdi-door-open',
          title: this.$t('menu.main.vacancySearch'),
          single: true,
          link: '/home/vacancy-search',
          items: []
        },
        {
          icon: 'mdi-file-document-edit-outline',
          title: this.$t('menu.main.contractDetails'),
          single: true,
          link: '/home/contract-information',
          items: [
          ]
        },
        {
          icon: 'mdi-history',
          title: this.$t('menu.main.reservationAndUsageHistory'),
          single: true,
          link: '/home/booking',
          items: [
          ]
        },
        {
          icon: 'mdi-smart-card-outline',
          title: this.$t('menu.main.membershipInformation'),
          single: true,
          link: '/home/member-info',
          items: [
          ]
        },
        {
          icon: 'mdi-ticket-percent-outline',
          title: this.$t('menu.main.exchangePoints'),
          single: true,
          link: '/home/exchange-points',
          items: []
        },
        {
          icon: 'mdi-bell-outline',
          title: this.$t('menu.main.vacancyNotification'),
          single: true,
          link: '/home/vacancy-notification',
          items: []
        }
      ],
      listSubMenu: [
        {
          title: this.$t('menu.sub.newsFromTokyuVacations'),
          single: true,
          link: '/home/news'
        },
        {
          title: this.$t('menu.sub.vacationLifeBackIssues'),
          single: true,
          link: '/home/download-documents/vacation-life-back-number'
        },
        {
          title: this.$t('common.logout'),
          single: true,
          link: '/home/logout'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../../assets/styles/modules/variables-scss' as v;

::v-deep {
  .no-translate {
    .v-icon {
      transform: none !important;
    }
  }
  .row {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    .v-list-item:not(.v-list-group__header) {
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
      .v-list-item__content {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 22px;
          width: 15px;
          height: 1px;
          background-color: var(--color__white);
        }
      }
      .v-list-item__title {
        padding-left: 22px;
      }
    }
  }
  .v-list {
    background: none !important;
    * {
      color: var(--color__white) !important;
    }
    .v-list-group {
      border-bottom: solid 1px var(--color__light-blue);
      &__header .v-icon {
        font-size: 30px;
      }
    }
    .v-list-item__title:not(.text-heading) {
      font-weight: var(--font-weight__light) !important;
    }
    .--sub .v-list-item__title {
      white-space: pre-wrap !important;
      line-height: 1.5;
    }
    &.--header {
      padding: 8px 30px 70px 30px;
      .v-list-group {
        border-bottom: solid 1px var(--color__light-blue--blur);
      }
    }
    &.--footer {
      .v-list-item {
        padding-left: 16px !important;
        &__title {
          font-size: var(--font-size_regular) !important;
        }
      }
      .text-heading {
        font-size: var(--font-size__medium) !important;
      }
      @media (min-width: v.$breakpoint__md) {
        .v-list-group {
          border-bottom: none;
          &:not(.no-translate) {
            .v-list-group__header {
              pointer-events: none;
            }
          }
        }
        .v-list-item {
          &__icon {
            display: none !important;
          }
        }
        .row {
          max-width: 100%;
          .col-flex {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
        }
        .v-list-item:not(.v-list-group__header) {
          .v-list-item__content {
            &:after {
              width: 4px;
              height: 4px;
              top: 19px;
              border-radius: 50%;
            }
            .v-list-item__title {
              padding-left: 12px;
              font-size: var(--font-size__normal);
            }
          }
        }
        .--sub {
          &::after {
            display: none;
          }
          .v-list-item__title {
            padding-left: 0 !important;
          }
        }
      }
      @media (min-width: v.$breakpoint__lg) {
        .col-flex {
          padding-right: 20px !important;
        }
      }
    }
  }
}
</style>
