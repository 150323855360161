import { cardExpireMonths } from '@/utils/get-date'
import actions from './actions'

const client = {
  state: {
    cardInfo: null,
    paymentPref: null,
    gmoShopId: null,
    maintenanceFrom: null,
    maintenanceTo: null,
    maintenanceMessage: null,
    available: null
  },
  mutations: {
    setCardInfo (state, card) {
      state.cardInfo = card
    },
    setCcStatus(state, {gmoShopId, available, maintenanceFrom, maintenanceTo, maintenanceMessage}) {
      state.gmoShopId = gmoShopId
      state.available = available
      state.maintenanceFrom = maintenanceFrom
      state.maintenanceTo = maintenanceTo
      state.maintenanceMessage = maintenanceMessage
    },
    setPrefPaymentMethod (state, {
      accommodationFeePayment,
      facilityFeePayment,
      setupFeePayment,
      optionalCleaningPayment,
      taxPayment,
      otherServicePayment
    }) {
      state.paymentPref = {
        accommodationFeePayment,
        facilityFeePayment,
        setupFeePayment,
        optionalCleaningPayment,
        taxPayment,
        otherServicePayment
      }
    }
  },
  getters: {
    gmoShopId: state => state.gmoShopId,
    isCcavailable: state => state.available,
    ccMaintenanceFrom: state => state.maintenanceFrom,
    ccMaintenanceTo: state => state.maintenanceTo,
    ccMaintenanceMessage: state => state.maintenanceMessage,
    hasValidCard: state => !!state.cardInfo,
    arePaymentMethodsSet: state => {
      return state.paymentPref &&
        Object.values(state.paymentPref).every(v => v)
    },
    cardExpireMonths: state => cardExpireMonths(state.cardInfo?.expire)
  },
  actions
}

export default client
