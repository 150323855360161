<template>
  <footer class="main-layer__front main-layer__front__top">
    <div class="t-container py-12">
      <ListMenu />
      <p>Copyright © Tokyu Corporation All Right Reserved</p>
    </div>
    <CallCenterDialog />
  </footer>
</template>

<script>
import ListMenu from './ListMenu'
import CallCenterDialog from '@/components/CallCenter/CallCenterDialog'

export default {
  name: 'AppLayoutFooter',
  components: {
    ListMenu,
    CallCenterDialog
  }
}
</script>

<style lang="scss" scoped>
@use '../../../assets/styles/modules/variables-scss' as v;

footer {
  position: relative;
  z-index: 1;
  margin-top: auto;
  flex-shrink: 0;
  background-color: var(--color__primary-light);
  border-radius: 24px 24px 0 0;
  @media (min-width: v.$breakpoint__md) {
    background-color: var(--color__primary);
    border-radius: 0;
  }
  p {
    text-align: center;
    color: var(--color__white);
    margin-top: 50px;
    margin-bottom: 0;
    font-size: var(--font-size__small);
  }
}
</style>
