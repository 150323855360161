<template>
  <span>
    <template v-for="(line, index) in lines">
      {{ line }}<br :key="index" v-if="index < lines.length - 1" />
    </template>
  </span>
</template>

<script>
export default {
  props: {
    value: String
  },
  computed: {
    lines () {
      if (this.value) {
        return this.value.split('\n')
      }
      return []
    }
  }
}
</script>
