<template>
  <div class="wrapper" :class="$route.meta.whiteBackground ? 'bg-white' : 'bg-silver'">
    <AppLayoutHeader />
    <AppLayoutMain class="mb-8 mb-md-0" style="min-height: 250px" />
    <AppLayoutFooter/>
    <OverLayLoading />
  </div>
</template>

<script>
import {
  AppLayoutHeader,
  AppLayoutMain,
  AppLayoutFooter
} from './components'
import OverLayLoading from '@/components/OverLayLoading'

export default {
  name: 'AppLayout',
  components: {
    AppLayoutHeader,
    AppLayoutMain,
    AppLayoutFooter,
    OverLayLoading
  },
  data () {
    return {
      isScroll: false
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
