import gql from 'graphql-tag'

export const GET_MY_AVAILABLE_POINTS = gql`
  query {
    myAvailablePoints {
      contractPoints {
        contractId
        contract {
          productTypeId
          productType {
            name
          }
        }
        pointRemains
      }
      tvpPoint
    }
  }
`

export const MY_CONTRACT_LIST = gql`
  query ($today: DateString)  {
    myContractList(pagination: {take: 50, skip: 0}) {
      items {
        id # contractId
        productType{
          id #Product ID 商品ID
          name # Product name 商品名（ex.マイウィーク）
        }
        startYear #Year of MW/PW start use MW/PWの開始利用年
        mwPwRoomType {
          name # Name of MW/PW room type MW/PWの部屋タイプ名
          facility {
            name: nameOnClientWeb # Name of the MW/PW facility MW/PWの施設名
          }
        }
        mwPwWeekNumber #MW/PW week number MW/PW の週番号
        mwExchangeFee #Exchange fee (excluding tax) 交換手数料（税抜き）

        startDate # Start date of contract 契約の開始日
        vmRoomType {
          name # Name of VM room type VMの部屋タイプ名
          facility {
            name: nameOnClientWeb # Name of VM's facility VMの施設名
          }
        }
        vmStayRightDate {
          startDate
          endDate
        }
        pointSummary {
          validFrom
          validTo
          pointRemains
        }
        mwUsageRights (fromDate: $today) {# array
          year
          status
          currentRights { # array, if there are 2 show 2 lines of facility.name and bookWeekNo
            bookRoomType {
              facility {
                name: nameOnClientWeb
              }
            }
            bookWeekNo
          }
        }

      }
    }
  }
`

export const MY_PW_CONTRACT = gql`
  query ($contractId: Int!) {
    myContract(id: $contractId) {
      productType{name} # String! 契約
      contractNumber # String 契約番号
      years #Int! 契約年数
      endDate
      additionalPointPrice
    }
    # notice that the response shape is the same as for PW!!!
    myPwContractUsage(contractId: $contractId) {
      rights {
        year # year
            bookingGroup {
            bookings {
              id # Int!  予約ID
              checkInDate # DateString! チェックイン日
              checkOutDate # DateString! 最終日
              room {
                roomType {
                  facility {
                    name: nameOnClientWeb # String! 施設
                  }
                }
              }
            }
        }
      }
    }
  }
`

export const LOAD_CURRENT_CONTRACTS = gql`
  query {
    myContractList (pagination: { take: 100, skip: 0 }) {
      items {
        id
        canCheckInUntil
        canCheckOutUntil
        canCheckInFrom
        productTypeId
        productType{
          id
          name
        }
        startYear
        mwPwRoomType {
          name
          facility {
            name: nameOnClientWeb
          }
        }
        mwPwWeekNumber
        mwExchangeFee

        startDate
        vmRoomType {
          name
          facility {
            name: nameOnClientWeb
          }
        }
      }
    }
  }
`

export const MY_SP_CONTRACT = gql`
  query ($contractId: Int!) {
    myContract (id: $contractId) {
      productType {
        name
      } # String! 契約
      contractNumber # String 契約番号
      years #Int! 契約年数
      endDate
      additionalPointPrice # Int! the price of the additional point (use this value to determine the price for purchaseMyPoints
      pointSummary {
        year
        validFrom # DateString! 期限開始
        validTo # DateString! 期限終了
        pointRemains # Int! 残ポイント
      }
      pointMovementList {
        changedAt # DateTime! 日時
        validFrom # DateString! 期限開始
        validThru #DateString! 期限終了
        pointChange # Int! ポイント名
      }
    }
  }
`
export const GET_CONTRACT_POINTS_SUMMARY = gql`
  query ($contractId: Int!) {
    myContractPointSummary(contractId: $contractId) {
      year
      validFrom
      validTo
      pointRemains
    }
  }
`

export const MY_VS_CONTRACT = gql`
  query {
    myVsContract {
      id
      productType{ name } # String! 契約
      contractNumber # String 契約番号
      client {
        memberId # String 会員番号
      }
      autoRenewal #Boolean! 契約自動更新設定 if true show "契約を自動更新する" if false show "契約を事項更新しない"
      startDate #DateString! 契約開始日. from startDate to today length should be displayed in "契約継続年数"
      endDate #DateString 更新予定日（満了日）
      canCancelAutoRenewal
    }
  }
`

export const UPDATE_VS_CONTRACT = gql`
  mutation (
     $autoRenewal: Boolean!
     $contractId: Int!
  ) {
    updateVsAutoRenewal (
      autoRenewal: $autoRenewal
      contractId: $contractId
    ) {
      id
    }
  }
`
