import gql from 'graphql-tag'

const BookingFragment = `fragment bookingCard on Booking {
  id # Booking ID 予約ID
  checkInDate
  checkOutDate
  bookingTypeId
  cancelledAt
  representative {
    name
  }
  room {
    roomType {
      name # Room type name 部屋タイプ名
      facility {
        name: nameOnClientWeb #Facility Name 施設名
        mainImageUrl
      }
    }
  }
  stayConfirmedAt
}`

export const LIST_NEAR_FUTURE_BOOKINGS = gql`
  query(
    $today: DateString!
    $recentToDate: DateString!
  ) {
    myBookingList(
      filter: { checkInDateFrom: $today, checkInDateTo: $recentToDate }
      orderBy: { fieldName: "booking.checkInDate", direction: "ASC" }
    ) {
      items {
        ...bookingCard
      }
    }
  }

  ${BookingFragment}
`

export const LIST_FUTURE_BOOKINGS = gql`
  query(
    $recentToDatePlus1: DateString!
  ) {
    myBookingList(
      filter: {
        checkInDateFrom: $recentToDatePlus1
      }
      orderBy: { fieldName: "booking.checkInDate", direction: "ASC" }
    ) {
      items {
        ...bookingCard
      }
    }
  }

  ${BookingFragment}
`

export const LIST_PAST_BOOKINGS = gql`
  query (
    $bookingTypeId: Int!
    $yesterday: DateString!
    $skip: Int!
    $take: Int!
  ) {
    myBookingList(
      filter: {
        checkInDateTo: $yesterday
        bookingTypeId: $bookingTypeId
      }
      orderBy: { fieldName: "booking.checkInDate", direction: "DESC" }
      pagination: {
        skip: $skip
        take: $take
      }
    ) {
      items {
        ...bookingCard
      }
    }
  }

  ${BookingFragment}
`

const GetBookingFragment = `
  fragment getBooking on Booking {
    id
    bookingTypeId
    cancelledAt
    canModifyByClient
    checkInDate
    checkOutDate
    checkedInAt
    bookingPlanId
    bookingGroup {
      id
      createdSubMemberId
    }
    bookingPlan { title }
    parkingRequestedNumber
    petInfo
    room {
      roomTypeId
      roomType {
        name
        maxOccupancy
        bedShareMaxOccupancy
        acceptsPet
        maxPetCount
        facility {
          id
          name: nameOnClientWeb
          mainImageUrl
          facilityHpUrl
          maxPetCount
          hasPetWeightRestriction
          petTermUrl
          catAllowed
          acceptsSameDayBooking
        }
      }
    }
    contract {
      id
      productTypeId
      canCheckInUntil
      canCheckOutUntil
      canCheckInFrom
      productType {
        name
      }
    }
    id
    guests {
      id
      child
      bedShared
      name
      address1
      age
      sex
      tel
      email
      nationality
      passportNumber
      passportPhoto
      passportPhotoUrl
      representative
      occupation
      stayDateRange {
        fromDate
        toDate
      }
    }
    rentalItems {
      rentalItemReservationId: id
      quantity
      fromDate
      toDate
      rentalItemId
      unitPrice
      rentalPeriod
      rentalItem {
        rentalItemDef {
          name
        }
      }
    }
    parkingRequestedNumber
    parkings {
      id
      carNumber
      color
      fromDate
      toDate
      facilityParkingId
    }
    optionalCleaningTotal: chargeTotal(
      typeIds: 5
    ) {
      totalJpy
    }
    accommodationFeeTotal: chargeTotal(
      typeIds: [1, 2]
    ) {
      totalJpy
      totalSp
      totalFp
    }
    onlyAccommodationFeeTotal: chargeTotal ( # this is redundant as it is the same as accommodationFeeTotal, but leaving it commented for the reference.
      typeIds: [1, 2]
    ) {
      totalJpy
      totalSp
      totalFp
    }
    finalizedInvoices {
      pdfFileUrl
      receiptId
    }
    bookingPlanAnswer {
      answer1
      answer2
      answer3
      answer4
      answer5
    }
  }
`
export const GET_BOOKING = gql`
  query ($bookingId: Int!) {
    myBooking (
      id: $bookingId
    ) {
      ...getBooking
    }

    getMyPrePaymentInfo (bookingId: $bookingId) {
      prepaymentAmount
      targetCharges
      remainingPoint
      remainigCharges
      currentTvp
    }
  }
  ${GetBookingFragment}
`

export const GET_BOOKING_WITH_CODE = gql`
  query ($bookingId: Int!, $code: String!) {
    myBooking: myBookingNonLogin (
      id: $bookingId
      code: $code
    ) {
      ...getBooking
    }
  }
  ${GetBookingFragment}
`

export const PRE_CHECK_IN = gql`
  mutation (
    $bookingId: Int!
    $guests: [MyBookingPreCheckInFilterInput!]!
  ) {
    preCheckIn: preCheckInMyBooking (
      bookingId: $bookingId
      data: $guests
    )
  }
`

export const PRE_CHECK_IN_WITH_CODE = gql`
  mutation (
    $bookingId: Int!
    $guests: [MyBookingPreCheckInFilterInput!]!
    $code: String!
  ) {
    preCheckIn: preCheckInMyBookingNonLogin (
      bookingId: $bookingId
      data: $guests
      code: $code
    )
  }
`

export const CONFIRM_STAY = gql`
  mutation ($bookingId: Int!) {
    confirmMyStay (
      bookingId: $bookingId
    ) {
      __typename
    }
  }
`

export const CALCULATE_EXTEND_FEE = gql`
  query (
    $bookingId: Int!
    $days: [DateString!]!
  ) {
    checkPriceForMyBookingExtension (
      bookingId: $bookingId
      days: $days
    ) {
      days {
        stayDate # Date of stay Attention! The price for the entire reservation will be included. Please filter and display only the extended amount. 宿泊日 注意！予約の全日程の料金が入ります。延長した分だけをFilterして表示して下さい
        accommodationPrice # Charges for this day of stay (Japanese Yen) この宿泊日の料金（日本円）
        accommodationPriceSp # Price SP 料金SP
        accommodationPriceFp # Price FP 料金FP
      }
    }
  }
`

export const CALCULATE_MOVE_FEE = gql`
query checkPriceForMyBookingMove (
  $bookingId: Int!
  $newCheckInDate: DateString!
  $newCheckOutDate: DateString!
) {
  checkPriceForMyBookingMove(
    bookingId: $bookingId
    newCheckInDate: $newCheckInDate
    newCheckOutDate: $newCheckOutDate
    extendParking: true
    extendRentalItems: false
  ) {
    days {
      stayDate # Date of stay Attention! The price for the entire reservation will be included. Please filter and display only the extended amount. 宿泊日 注意！予約の全日程の料金が入ります。延長した分だけをFilterして表示して下さい
      accommodationPrice # Charges for this day of stay (Japanese Yen) この宿泊日の料金（日本円）
      accommodationPriceSp # Price SP 料金SP
      accommodationPriceFp # Price FP 料金FP
      fees {
        priceWithSalesTax
      }
    }
    cancelPenalties {
      stayDate
      price
      spPoint
      fpPoint
      penaltyRate
      penaltyPrice
      penaltySpPoint
      penaltyFpPoint
    }
    parkingReservation {
      hasTarget
      canExtend
    }
    rentalItemReservation {
      hasTarget
      canExtend
    }
  }
}`

export const EXTEND_BOOKING = gql`
  mutation (
    $bookingId: Int!
    $days: [DateString!]!
    $password: String!
    $newPrePayment: Int
  ) {
    extendMyBooking (
      bookingId: $bookingId
      days: $days
      password: $password
      bookingPrePayment: {
        amount: $newPrePayment
      }
    )
  }
`

export const MOVE_BOOKING = gql`
mutation (
  $password: String!
  $bookingId: Int!
  $newCheckInDate: DateString!
  $newCheckOutDate: DateString!
  $extendParking: Boolean!
  $extendRentalItems: Boolean!
  $newPrePayment: Int
  $cancelUseTvp: Int
) {
  moveMyBooking(
    bookingId: $bookingId
    newCheckInDate: $newCheckInDate
    newCheckOutDate: $newCheckOutDate
    extendParking: $extendParking
    extendRentalItems: $extendRentalItems
    password: $password
    bookingPrePayment: {
      amount: $newPrePayment
    }
    cancelUseTvp: $cancelUseTvp
  )
}`

export const CALCULATE_CANCEL_PENALTY = gql`
  query (
    $bookingId: Int!
    $cancelDates: [DateString!]!
  ) {
    calculateMyBookingCancelPenalty (
      bookingId: $bookingId
      stayDates: $cancelDates
    ) {
      penalties {
        stayDate
        price
        spPoint
        fpPoint
        penaltyRate
        penaltyPrice
        penaltySpPoint
        penaltyFpPoint
      }
    }
  }
`

export const CANCEL_BOOKING = gql`
  mutation (
    $bookingId: Int!
    $cancelDates: [DateString!]!
    $password: String!
    $useTvp: Int
    $paymentMethod: BookingCancelPaymentMethod
    $newPrePayment: Int
  ) {
    cancelMyBooking (
      bookingId: $bookingId
      stayDates: $cancelDates
      password: $password
      useTvp: $useTvp
      paymentMethod: $paymentMethod
      bookingPrePayment: {
        amount: $newPrePayment
      }
    ) {
      __typename
    }
  }
`

export const CHANGE_BOOKING_GUESTS = gql`
  mutation (
    $bookingId: Int!
    $guests: [UpdateMyBookingGuestDataInput!]!
    $representative: UpdateMyBookingGuestDataInput!
    $password: String!
    $newPrePayment: Int
  ) {
    updateMyBookingGuests (
      bookingId: $bookingId
      data: $guests
      representative: $representative
      password: $password
      bookingPrePayment: {
        amount: $newPrePayment
      }
    )
  }
`

export const UPDATE_RENTAL_ITEMS = gql`
  mutation (
    $bookingId: Int!
    $rentalItems: [UpdateMyRentalItemInput!]!
  ) {
    updateMyBookingRentalItems (
      bookingId: $bookingId
      rentalItems: $rentalItems
    )
  }
`

export const UPDATE_PARKINGS = gql`
  mutation (
    $bookingId: Int!
    $parkings: [UpdateMyFacilityParkingReservationInput!]!
  ) {
    updateMyBookingParking (
      bookingId: $bookingId
      parkings: $parkings
    )
  }
`

export const UPDATE_BOOKING_INFO = gql`
  mutation (
    $bookingId: Int!
    $data: UpdateMyBookingInput!
  ) {
    updateMyBooking (
      bookingId: $bookingId
      data: $data
    )
  }
`
