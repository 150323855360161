<!--done!!!-->
<template>
  <v-dialog :value="callCenterDialog" width="378" content-class="bg-silver">
    <div class="text-right">
      <button
        @click.stop="close"
        class="btn-close bg-primary"
      >
        <div class="btn-close__icon" />
      </button>
    </div>
    <div class="d-flex justify-center">
      <IconPhone width="35" height="35" />
      <h3 class="text-heading --prm ml-2">
        {{ $t('booking.callCenter') }}
      </h3>
    </div>

    <div class="pt-6 pb-8 px-10">
      <v-btn
        elevation="0"
        @click="makeCall"
        rounded
        color="btn-grad--prm"
        block
        x-large
      >
        {{ telNumber }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import IconPhone from '@/components/icons/IconPhone'
export default {
  name: 'CallCenterDialog',
  components: {
    IconPhone
  },
  data () {
    return {
      telNumber: process.env.VUE_APP_CALL_CENTER_TEL_NUMBER
    }
  },
  computed: {
    callCenterDialog () {
      return this.$store.state.common.callCenterDialog
    }
  },
  methods: {
    makeCall () {
      if (this.$isMobile()) {
        window.open(`tel:${this.telNumber}`)
      }
    },
    close () {
      return this.$store.commit('showCallCenterDialog', false)
    }
  }
}
</script>
