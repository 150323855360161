import Vue from 'vue'
import VueRouter from 'vue-router'
import Page404 from '@/views/Page404'
import i18n from '@/plugins/i18n'
import publicRouter from './modules/publicRouter'
import ensureAuthenticated from './modules/ensureAuthenticated'
import privateRouter from './modules/privateRouter'
import ensureRemoveTemporaryBooking from './modules/ensure-remove-temporary-booking'
import checkMaintenance from './modules/check-maintenance'

Vue.use(VueRouter)

const routes = [
  publicRouter,
  privateRouter,
  {
    path: '*',
    name: 'not-found',
    component: Page404,
    meta: { title: i18n.t('title.pageNotFound') }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach(checkMaintenance)
router.beforeEach(ensureAuthenticated)
router.beforeEach(ensureRemoveTemporaryBooking)

export default router
