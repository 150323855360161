<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 32.5 32.5">
    <path id="Контур_7721" data-name="Контур 7721" d="M128,247.606a2.193,2.193,0,0,0-1.463.723l-2.266,2.265a.56.56,0,1,0,.79.794l.782-.78,4.583,4.694a.56.56,0,1,0,.8-.781l-4.593-4.7.7-.694a1.107,1.107,0,0,1,.708-.4,1.018,1.018,0,0,1,.739.4l4.807,4.806a.544.544,0,0,1,0,.79l-2.6,2.6a2.51,2.51,0,0,0-.8,2.058,3,3,0,0,0,.653,1.48,45.264,45.264,0,0,0,11.4,11.4,2.991,2.991,0,0,0,1.475.653,2.52,2.52,0,0,0,2.063-.8l.772-.773,4.589,4.687a.56.56,0,1,0,.8-.781l-4.6-4.7,1.027-1.028a.549.549,0,0,1,.794,0l4.806,4.811a1.02,1.02,0,0,1,.4.739,1.108,1.108,0,0,1-.4.708l-2.265,2.265a3.929,3.929,0,0,1-2.964.955c-2.934,0-8.153-1.106-15.843-8.8s-8.8-12.9-8.8-15.838a7.659,7.659,0,0,1,.05-.992.561.561,0,0,0-1.112-.141,8.629,8.629,0,0,0-.058,1.133c0,3.224,1.289,8.8,9.122,16.632s13.412,9.122,16.637,9.122a5.034,5.034,0,0,0,3.753-1.281l2.269-2.269a2.19,2.19,0,0,0,.718-1.463,2.157,2.157,0,0,0-.718-1.567l-4.812-4.806a1.684,1.684,0,0,0-2.372,0l-2.6,2.591a1.276,1.276,0,0,1-1.136.484,2.014,2.014,0,0,1-.926-.426,44.232,44.232,0,0,1-11.2-11.2,2.02,2.02,0,0,1-.43-.926,1.275,1.275,0,0,1,.483-1.136l2.6-2.592a1.69,1.69,0,0,0,0-2.376l-4.806-4.807a2.168,2.168,0,0,0-1.57-.723Zm12.9,2.823a11.751,11.751,0,0,1,11.759,11.759.56.56,0,1,0,1.12,0,12.89,12.89,0,0,0-12.906-12.875.559.559,0,0,0,.027,1.115Zm0,2.241a.56.56,0,0,0,0,1.12,8.39,8.39,0,0,1,8.4,8.4.56.56,0,0,0,1.12,0,9.527,9.527,0,0,0-9.519-9.519Zm0,3.359a.56.56,0,0,0,0,1.121,5.029,5.029,0,0,1,5.038,5.038.56.56,0,1,0,1.121,0,6.168,6.168,0,0,0-6.159-6.159Zm0,4.482a1.672,1.672,0,0,1,1.683,1.678.56.56,0,1,0,1.12,0,2.816,2.816,0,0,0-2.83-2.8.561.561,0,1,0,.027,1.121Zm0,0" transform="translate(-122.988 -247.605)" fill="#0079c9"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPhone',
  props: {
    width: {
      type: Number,
      default: 32.5
    },
    height: {
      type: Number,
      default: 32.5
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>

<style scoped>

</style>
