var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-silver h-100"},[(!_vm.$route.meta.hideHeader)?_c('PublicLayoutHeader',{staticClass:"mt-8 mb-4"}):_vm._e(),_c('PublicLayoutMain',{staticClass:"wrapper",class:{
      'wrapper__no-header': _vm.$route.meta.hideHeader && !_vm.$route.meta.hideFooter && !_vm.$route.meta.isMiniFooter,
      'wrapper__no-header__mini': _vm.$route.meta.hideHeader && _vm.$route.meta.isMiniFooter,
      'wrapper__no-footer': _vm.$route.meta.hideFooter && !_vm.$route.meta.hideHeader,
      'wrapper__no-header__no-footer': _vm.$route.meta.hideFooter && _vm.$route.meta.hideFooter,
      'wrapper__common': !_vm.$route.meta.hideHeader && !_vm.$route.meta.hideFooter && !_vm.$route.meta.isMiniFooter,
      'wrapper__common__mini': !_vm.$route.meta.hideHeader && !_vm.$route.meta.hideFooter && _vm.$route.meta.isMiniFooter
    }}),(_vm.$route.meta.isMiniFooter && !_vm.$route.meta.hideFooter)?_c('PublicLayoutFooter'):_vm._e(),(!_vm.$route.meta.isMiniFooter && !_vm.$route.meta.hideFooter)?_c('AppLayoutFooter'):_vm._e(),_c('OverLayLoading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }