<template>
  <header class="text-center">
    <router-link to="/home">
      <Logo />
    </router-link>
    <div>{{ $t("title.membersOnlySiteLogin") }}</div>
  </header>
</template>

<script>
import Logo from '@/components/icons/Logo';

export default {
  name: 'PublicLayoutHeader',
  components: {
    Logo
  }
}
</script>
